export default {
  1.1: [
    "Firmeza y determinación.",
    "Gran sentido de urgencia.",
    "Cumplimiento de los plazos, pero flexibilidad en las normas.",
    "Dar una gran importancia al tiempo.",
    "Ser muy espontáneo, creativo, independiente y saber propiciar los cambios.",
    "Experimentar con nuevas, mejores y más rápidas formas de hacer las cosas.",
    "Dar opiniones, ideas y argumentos.",
    "Gran confianza en sí mismo.",
    "Ser autodidacta en muchas áreas.",
    "Beneficiar la obtención de resultados más que la armonía en las relaciones o el cumplimiento de protocolos.",
    "Trabajar en proyectos creativos e innovadores.",
    "Resolver problemas y asumir retos.",
    "Al enfrentar problemas complicados, trabajar hasta conseguirle una solución satisfactoria.",
    "Ser lógico e incisivo frente a los problemas.",
    "Una actitud enérgica a la hora de comenzar algo.",
    "Tomar riesgos sin pensarlo mucho. Enfrentar los retos de forma directa.",
    "Tener autoridad total para asumir las responsabilidades.",
    "Alta confianza en sí mismo.",
    "Gran capacidad resolutiva.",
    "Autogestión personal.",
    "Saber presionar a las personas para que hagan la tarea.",
    "Capacidad para hablar.",
    "Ser directo al hablar y defender sus posiciones.",
    "Ser multitasking.",
    "No tener que revisar mucha información antes de tomar decisiones.",
    "Espíritu emprendedor.",
    "Ser exigente consigo mismo y con los demás.",
    "Ambición y deseos de logro.",
    "Percibir aspectos en las situaciones laborales que a otros se les puede pasar por alto.",
    "Ver globalmente e integrar las partes en un todo.",
    "Visión de futuro."
  ],
  1.2: [
    "Tendencia a la firmeza y determinación.",
    "Sentido de urgencia.",
    "Cumplimiento de los plazos.",
    "Dar importancia al tiempo.",
    "Ser espontáneo, creativo, independiente y saber propiciar los cambios.",
    "Tender a experimentar con nuevas, mejores y más rápidas formas de hacer las cosas.",
    "Tender a dar opiniones, ideas y argumentos.",
    "Confianza en sí mismo.",
    "Ser autodidacta en algunas áreas.",
    "Beneficiar la obtención de resultados más que las relaciones o los protocolos.",
    "Trabajar en proyectos creativos e innovadores.",
    "Resolver problemas y asumir retos.",
    "Al enfrentar problemas complicados, trabajar hasta conseguirle una solución satisfactoria.",
    "Tender a ser lógico frente a los problemas.",
    "Una actitud activa a la hora de comenzar algo.",
    "Tender a tomar riesgos y enfrentar retos.",
    "Tener autoridad para asumir las responsabilidades.",
    "Confianza en sí mismo.",
    "Capacidad resolutiva.",
    "Autogestión personal.",
    "Saber presionar a las personas para que hagan la tarea.",
    "Capacidad para hablar firmemente.",
    "Tender a ser multitasking.",
    "No tener que revisar mucha información antes de tomar decisiones.",
    "Espíritu emprendedor.",
    "Tender a ser exigente consigo mismo y con los demás.",
    "Con deseos de logro.",
    "Tender a percibir aspectos en las situaciones laborales que a otros se les puede pasar por alto.",
    "Tender a ver globalmente e integrar las partes en un todo.",
    "Visión de futuro."
  ],
  2.1: [
    "Ser muy positivo, optimista, entusiasta y con buen sentido del humor.",
    "Gran capacidad para hacer comentarios agradables, graciosos y encantadores.",
    "Alta habilidad para aliviar momentos de tensión.",
    "Mucha agilidad para cambiar estados de ánimo, de serios o tensos a suaves y positivos, de forma ligera, sutil y amigable.",
    "Ser muy popular, el alma de la fiesta.",
    "Ser muy extrovertido y espontáneo.",
    "Gran capacidad para motivar y entusiasmar.",
    "De ritmo muy rápido en la acción y en la toma de decisiones sobre todo cuando involucra a las personas.",
    "Poca o ninguna supervisión estricta.",
    "Invertir mucha energía en relacionarse con los demás.",
    "Trabajar en equipo en vez de solo.",
    "Trabajo colaborativo.",
    "Mucha confianza en los demás, en sus habilidades para aportar al equipo y en la responsabilidad de asumir los compromisos.",
    "Mucha confianza en sí mismo y en sus habilidades.",
    "Tener una gran inclinación a establecer nuevas relaciones sociales, tratar con personas, en un ambiente positivo y agradable.",
    "Dar mucho valor al aspecto emocional y los sentimientos de las personas.",
    "Ser muy intuitivo a la hora de conocer a las personas.",
    "Un gran deseo de ayudar a los demás.",
    "Estilo amigable e influenciador a la hora de dirigir.",
    "Gran habilidad para solucionar los problemas de la gente.",
    "Estar muy disponible para los demás.",
    "Gran habilidad para negociar los conflictos entre las personas.",
    "Alta capacidad para lograr que los demás se muestren abiertos y confíen.",
    "Alta habilidad para influenciar y motivar con la palabra.",
    "Alta habilidad para crear un ambiente laboral, informal y relajado.",
    "Gran capacidad de relacionarse bien con casi todas las personas, incluso con desconocidos.",
    "Muchos contactos claves.",
    "Alta capacidad para influir en los demás y ser optimista a la hora de convencer y persuadir.",
    "Una alta orientación a las personas y a las relaciones.",
    "Gran capacidad para motivar a otros a alcanzar sus objetivos."
  ],
  2.2: [
    "Ser positivo, optimista, entusiasta y con buen sentido del humor.",
    "Capacidad para hacer comentarios agradables, graciosos y encantadores.",
    "Habilidad para aliviar momentos de tensión.",
    "Agilidad para cambiar estados de ánimo, de serios o tensos a suaves y positivos, de forma ligera, sutil y amigable.",
    "Ser popular, el alma de la fiesta.",
    "Ser extrovertido y espontáneo.",
    "Capacidad para motivar y entusiasmar.",
    "De ritmo rápido en la acción y en la toma de decisiones sobre todo cuando involucra a las personas.",
    "Poca supervisión estricta.",
    "Invertir energía en relacionarse con los demás.",
    "Trabajar en equipo en vez de solo.",
    "Trabajo colaborativo.",
    "Confiar en los demás, en sus habilidades para aportar al equipo y en la responsabilidad de asumir los compromisos.",
    "Confiar en sí mismo y en sus habilidades.",
    "Tender a establecer nuevas relaciones sociales, tratar con personas, en un ambiente positivo y agradable.",
    "Dar valor al aspecto emocional y los sentimientos de las personas.",
    "Ser intuitivo a la hora de conocer a las personas.",
    "Deseos de ayudar a los demás.",
    "Estilo amigable e influenciador a la hora de dirigir.",
    "Habilidad para solucionar los problemas de la gente.",
    "Estar disponible para los demás.",
    "Habilidad para negociar los conflictos entre las personas.",
    "Capacidad para lograr que los demás se muestren abiertos y confíen.",
    "Habilidad para influenciar y motivar con la palabra.",
    "Habilidad para crear un ambiente laboral, informal y relajado.",
    "Capacidad de relacionarse bien con casi todas las personas, incluso con desconocidos.",
    "Contactos claves.",
    "Capacidad para influir en los demás y ser optimista a la hora de convencer y persuadir.",
    "Orientación a las personas y a las relaciones.",
    "Capacidad para motivar a otros a alcanzar sus objetivos."
  ],
  3.1: [
    "Una valiosa actitud positiva como miembro de un equipo.",
    "Ser muy detallista.",
    "Excelente capacidad para seguir lineamientos e instrucciones.",
    "Mucho autocontrol emocional.",
    "Una gran actitud conciliadora.",
    "Evitar los conflictos y confrontaciones.",
    "Ambiente de trabajo muy estable, con mucho tiempo para cambiar y hacerlo de forma planificada y organizada.",
    "Sin cambios repentinos y de ritmo pausado.",
    "Seguir patrones y protocolos de forma consistente.",
    "Trabajo muy rutinario.",
    "Afrontar retos de forma seria, inalterable, poco emocional.",
    "Mucha paciencia, además de su gran capacidad para armonizar.",
    "Ser estabilizador y sostenedor en los momentos tensos.",
    "Ser muy orientado al servicio.",
    "Ser muy colaborador.",
    "Relacionarse con grupos pequeños, personas conocidas y de confianza.",
    "Mucha facilidad para establecer relaciones profundas.",
    "Ser muy equilibrado y estable.",
    "Estar con los pies muy bien puestos sobre la tierra.",
    "Ser muy cercano y amistoso.",
    "Ser muy disciplinado.",
    "De actitud muy reservada.",
    "Ser muy tradicionalista y conservadora.",
    "Ser muy generoso y leal con los demás.",
    "Ser muy ordenado, limpio, organizado y metódico en el trabajo.",
    "Ser muy fiable.",
    "Ser un gran jugador de equipo."
  ],
  3.2: [
    "Actitud positiva como miembro de un equipo.",
    "Ser detallista.",
    "Capacidad para seguir lineamientos e instrucciones.",
    "Autocontrol emocional.",
    "Actitud conciliadora.",
    "Evitar los conflictos y confrontaciones.",
    "Ambiente de trabajo estable, con tiempo para cambiar y hacerlo de forma planificada y organizada.",
    "Sin cambios repentinos y de ritmo pausado.",
    "Seguir patrones y protocolos.",
    "Trabajo rutinario.",
    "Afrontar retos de forma seria y poco emocional.",
    "Paciencia, además de capacidad para armonizar.",
    "Ser estabilizador y sostenedor en los momentos tensos.",
    "Ser orientado al servicio.",
    "Ser colaborador.",
    "Relacionarse con grupos pequeños, personas conocidas y de confianza.",
    "Facilidad para establecer relaciones profundas.",
    "Ser equilibrado y estable.",
    "Estar con los pies bien puestos sobre la tierra.",
    "Ser cercano y amistoso.",
    "Ser disciplinado.",
    "Actitud reservada.",
    "Ser tradicionalista y conservadora.",
    "Ser generoso y leal con los demás.",
    "Ser ordenado, limpio y metódico en el trabajo.",
    "Ser fiable.",
    "Ser un jugador de equipo."
  ],
  4.1: [
    "Mantener estándares altos de calidad.",
    "Seguir consistentemente patrones, normas y procedimientos establecidos.",
    "Ser muy consciente.",
    "Tomar decisiones basadas en mucho análisis y revisión de mucha información y hechos.",
    "Probar, clarificar y conseguir toda la información posible antes de proceder.",
    "Ser muy realista.",
    "Ponerse metas y objetivos alcanzables.",
    "Ser muy objetivo.",
    "Comprender muy bien la situación antes de enfrentar un problema y buscar una solución.",
    "Conocer y estudiar la mayor cantidad de información posible respecto al problema.",
    "Pensamiento crítico.",
    "Una actitud muy meticulosa y detallista.",
    "Hacer el trabajo muy bien al primer intento.",
    "Desmenuzar todos los aspectos de una situación, visión de detalle más que global.",
    "Ser muy perfeccionista y evita cometer errores.",
    "Trata de tener todos los posibles riesgos bajo control, para evitar a toda costa equivocarse.",
    "Persuadir a los demás a través de los hechos, de la lógica, de la objetividad.",
    "Ser muy esmerado en los análisis.",
    "Controlar muy bien las emociones.",
    "Ser muy preciso, exacto y minucioso a la hora de resolver problemas.",
    "Una actitud muy conservadora a la hora de enfrentar los problemas.",
    "Estar muy centrado en los procesos.",
    "Ser muy preocupado en hacer las cosas bien.",
    "Ser muy cuidadoso de la calidad y de la excelencia.",
    "Mucha concentración en la ejecución de la tarea.",
    "Ser muy directo y honrado a la hora de presentar los hechos.",
    "Ser muy responsable en cumplir con los compromisos y los plazos.",
    "Prepararse y generar la confianza de un especialista, que sabe de la materia.",
    "Tomar muy en cuenta todas las implicaciones que tiene cualquier decisión.",
    "Cuando las decisiones son de rutina, tener la habilidad de ser sistemático y rápido.",
    "Cuando las decisiones son importantes y especiales, ser muy cauteloso.",
    "No decidir hasta tener la certeza de que la decisión es la correcta.",
    "Interesarse mucho en los métodos, los procedimientos y los estándares de calidad.",
    "Ser muy tradicional y convencional.",
    "Ser muy reservado y formal, modesto y conservador.",
    "Ser muy tranquilo emocionalmente. Mucho autocontrol.",
    "Ritmo pausado.",
    "Ser muy paciente.",
    "Mucha disciplina y diplomacia.",
    "Convicciones muy firmes.",
    "Muy buenos modales.",
    "Dar explicaciones muy detalladas, claras y precisas.",
    "Preguntar e indagar para comprobar la comprensión."
  ],
  4.2: [
    "Mantener estándares altos de calidad.",
    "Seguir patrones, normas y procedimientos establecidos.",
    "Ser consistente y consciente.",
    "Tomar decisiones basadas en análisis y revisión de información y hechos.",
    "Probar y clarificar antes de proceder.",
    "Ser realista.",
    "Ponerse metas y objetivos alcanzables.",
    "Ser objetivo.",
    "Comprender la situación antes de enfrentar un problema y buscar una solución.",
    "Conocer y estudiar información respecto al problema.",
    "Pensamiento crítico.",
    "Una actitud meticulosa y detallista.",
    "Hacer el trabajo bien al primer intento.",
    "Desmenuzar los aspectos de una situación, visión de detalle más que global.",
    "Ser perfeccionista y evita cometer errores.",
    "Tratar de tener los riesgos bajo control, para evitar equivocarse.",
    "Persuadir a los demás a través de los hechos, de la lógica, de la objetividad.",
    "Ser esmerado en los análisis.",
    "Controlar las emociones.",
    "Ser preciso, exacto y minucioso a la hora de resolver problemas.",
    "Una actitud conservadora a la hora de enfrentar los problemas.",
    "Estar centrado en los procesos.",
    "Preocuparse en hacer las cosas bien.",
    "Cuidar de la calidad y la excelencia.",
    "Concentración en la ejecución de la tarea.",
    "Tender a ser directo y honrado a la hora de presentar los hechos.",
    "Tender a ser responsable en cumplir con los compromisos y los plazos.",
    "Prepararse y generar la confianza de un especialista, que sabe de la materia.",
    "Tomar en cuenta las implicaciones que tiene cualquier decisión.",
    "Cuando las decisiones son de rutina, tener la habilidad de ser sistemático y rápido.",
    "Cuando las decisiones son importantes y especiales, ser cauteloso.",
    "No decidir hasta tener la certeza de que la decisión es la correcta.",
    "Interesarse en los métodos, los procedimientos y los estándares de calidad.",
    "Ser tradicional y convencional.",
    "Tender a ser reservado y formal, modesto y conservador.",
    "Tranquilidad emocional y autocontrol.",
    "Ser de ritmo pausado.",
    "Ser paciente.",
    "Disciplina, diplomacia.",
    "Tener convicciones firmes.",
    "Buenos modales.",
    "Dar explicaciones detalladas, claras y precisas.",
    "Preguntar e indagar para comprobar la comprensión."
  ],
  5: [
    "Respuestas rápidas a las crisis y a los cambios, con altas expectativas de obtener resultados de inmediato.",
    "Habilidad de liderar a las personas del equipo, con encanto e influencia.",
    "Habilidad de liderar y además determinación y firmeza a la hora de delegar.",
    "Actualizar constantemente el comportamiento, según la situación.",
    "Capacidad de cambiar.",
    "Mientras la situación es relajada y normal, ser capaz de influenciar a la gente y motivarla a través de la persuasión.",
    "Bajo presión y estrés, ser firme y determinante.",
    "Manejar el tiempo con sentido de urgencia.",
    "Habilidad para proyectar seguridad personal frente a los grupos numerosos o equipos de trabajo.",
    "Ser confiado y articulado.",
    "Estar enfocado en los resultados, a través de la gente.",
    "Ser muy activo.",
    "Conseguir que las cosas se hagan.",
    "Garantizar el logro de los objetivos.",
    "Ser independiente y con iniciativa.",
    "Habilidad de ver el panorama global y a la gente dentro de ese panorama.",
    "Poca supervisión, poco control y libre de detalles.",
    "Ser flexible con las normas y protocolos.",
    "Habilidad para gestionar muchas tareas o proyectos al mismo tiempo.",
    "Afronta los problemas y retos de forma creativa y directa.",
    "Habilidad de motivar a la gente para que participe.",
    "Ambiente de trabajo libre de rutina.",
    "Buen dominio del lenguaje persuasivo.",
    "Motivación para conseguir una nueva forma de hacer las cosas, más rápida y mejor.",
    "Enfrentar retos que no tengan precedentes.",
    "Resolver los problemas con la gente, con firmeza y con encanto personal.",
    "Seguridad y confianza en sí mismo.",
    "Creatividad e innovación."
  ],
  6: [
    "Ser decidido, ambicioso y optimista.",
    "Resolver los problemas de forma innovadora.",
    "Habilidad para resolver los conflictos entre las personas.",
    "Ser resolutivo en la superación de los obstáculos.",
    "Ser dinámico y seguro de sí mismo.",
    "Actitud positiva y entusiasta al momento de relacionarse con los demás.",
    "Influir sobre las demás a través del encanto y firmeza personal.",
    "Confiar en los demás.",
    "Trabajar en un entorno variable.",
    "Sentido de urgencia.",
    "Amar los cambios y un ambiente dinámico.",
    "Ser independiente.",
    "Informalidad.",
    "Dominio de la palabra.",
    "Autodeterminación a la hora de emprender una tarea o de resolver un problema.",
    "Libertad de controles y de supervisión estrecha.",
    "Libre de detalles.",
    "De ideas firmes.",
    "Ser parte activa en la toma de decisiones.",
    "Tomar riesgos.",
    "Obtener resultados a través de la gente.",
    "Buen manejo de los colaboradores y del equipo.",
    "Ser convincente.",
    "Ser persistente.",
    "Anhelo de logro.",
    "Altos niveles de exigencia para sí mismo y para los demás.",
    "Ser expresivo y dinámico.",
    "Ser amistoso, positivo y extrovertido.",
    "Mientras la situación es relajada y normal, ser capaz de influenciar y motivar a la gente a través de la persuasión.",
    "Bajo presión y estrés, ser firme y determinante.",
    "Habilidad para aproximarse de diferentes maneras a las personas.",
    "Visión orientada hacia el futuro de forma creativa.",
    "Ser multitasking.",
    "Adaptación al entorno, siendo capaz de regular el comportamiento, según la ocasión.",
    "Ser decidido, con una buena habilidad para influenciar en los demás.",
    "Proyectar una imagen amigable y receptiva mientras se tiene todo bajo control.",
    "Ser capaz de tomar distancia cuando sea necesario.",
    "Tendencia creativa y gusto por la innovación.",
    "Seguridad y confianza personal."
  ],
  7: [
    "Ser positivo, optimista, entusiasta y con buen sentido del humor.",
    "Capaz de hacer comentarios agradables, graciosos y encantadores.",
    "Saber cómo aliviar momentos de tensión.",
    "Hábil a la hora de influenciar a otros a través del encanto y la amabilidad.",
    "Ser expresivo y creativo.",
    "Saberse integrar y formar parte de un equipo.",
    "Estar orientado hacia las personas y los resultados.",
    "Cuando el ambiente se pone tenso, ser firme en su actitud y en la toma de decisiones.",
    "Cuando el ambiente es favorable, ser compresivo y de estilo amistoso.",
    "Saber trabajar con las puertas abiertas y ser accesible a todo el mundo.",
    "Saber trabajar en equipo y ser el líder cuando se requiera.",
    "Capacidad para motivar y entusiasmar.",
    "Claridad de cómo se deben hacer las cosas.",
    "Poder reflejar una imagen amigable.",
    "Saber ejercer la autoridad y manejarse en posiciones de liderazgo.",
    "Ser espontáneo y auténtico.",
    "Ambiente dinámico, poco rutinario, con poca supervisión y libre de detalles y controles.",
    "Capaz de tomar decisiones rápidas y arriesgadas.",
    "Habilidad para solucionar problemas en donde están involucradas las personas.",
    "Capacidad de influenciar a las personas.",
    "Capacidad para argumentar.",
    "Facultad de sentirse bien con las personas, incluso con los extraños.",
    "Frente al estrés y las presiones, ser casual y de actitud espontánea, sin dejar de darle la cara a los retos.",
    "Saber dejar en claro su posición, con firmeza.",
    "Ser extrovertido y entusiasta.",
    "Visión global a la hora de enfocar y entender los problemas.",
    "Ser consciente del impacto de las actitudes y decisiones en los demás.",
    "Habilidades para impulsar e iniciar proyectos.",
    "Aportar ideas creativas e innovadoras en la solución de problemas.",
    "Seguridad y confianza en sí mismo.",
    "Ser creativo e innovador."
  ],
  8: [
    "Asumir retos.",
    "Enfocarse en la tarea y en los resultados, pero con un buen sentido de equipo.",
    "Adaptarse al entorno si es necesario, para el logro de los objetivos.",
    "Ser de ritmo rápido y enérgico, con sentido de urgencia.",
    "Equilibrio en el estilo de comunicación, ser firme y directo al hablar y al mismo tiempo gentil y considerado.",
    "Ser generoso y bondadoso con los demás, de una manera activa.",
    "Presionar a los demás, de una forma gentil, para la obtención de resultados.",
    "Darle seguimiento a las promesas y compromisos.",
    "Cuando el ambiente está en calma, ser amigable y considerado.",
    "Cuando el ambiente se torna tenso, ser firme y determinado.",
    "Ser ético y leal.",
    "Garantizar la obtención de los resultados.",
    "Ser autónomo en la toma de decisiones para la obtención de resultados.",
    "Integrarse al equipo y aportar energía y equilibrio, para impulsar la obtención de resultados.",
    "Saber autodirigirse y a ser responsable con las tareas y metas.",
    "Estar al frente de varios retos a la vez.",
    "Ser creativo y reflexivo.",
    "Adaptarse a trabajar con los demás, sin perder la capacidad para trabajar de forma independiente cuando las circunstancias lo demanden.",
    "Estar disponible para la acción.",
    "Ser reflexivo antes de asumir compromisos.",
    "Ser recursivo para enfrentar los retos y problemas.",
    "Ser creativo a la hora de enfrentar retos complejos."
  ],
  9: [
    "Asumir retos y obtener resultados, a través del equipo de trabajo.",
    "Ser capaz de adaptarse al ritmo del entorno para el logro de los objetivos y de acelerar el paso si el proyecto lo amerita.",
    "Colaborar con los demás para avanzar en los resultados.",
    "Equilibrio en el estilo de comunicación, ser firme y directo al hablar y al mismo tiempo gentil y considerado.",
    "Ser generoso con los demás de una manera activa.",
    "Habilidad para impulsar a los demás, de una forma gentil, para la obtención de resultados.",
    "Ser amigable y considerado en la rutina y determinado y firme en las situaciones de tensión.",
    "Recurrir a la reflexión frente al estrés.",
    "Trabajar en equipo y también ser capaz de ser autónomo en la obtención de resultados, si la circunstancia lo amerita.",
    "Ser convincente y firme al comunicar los argumentos, una vez que haya reflexionado sobre ellos.",
    "Ser detallista y metódico en la solución de problemas rutinarios.",
    "Ser innovador y creativo con nuevos retos y desafíos.",
    "Entender que tanto las personas como los resultados son importantes.",
    "Saber elegir las prioridades leyendo el contexto.",
    "Saber autodirigirse y a ser responsable con las tareas y metas.",
    "Ser capaz de ver tanto lo global como los detalles, al momento de abordar un proyecto o un problema.",
    "Estar al frente de varios retos a la vez.",
    "Ser curioso, innovador y creativo y al mismo tiempo saber lidiar con la rutina y las metodologías establecidas.",
    "Saber estabilizar el ambiente en los momentos de tensión.",
    "Ser enérgico y amistoso a la hora de conectar con al equipo, para la obtención de los resultados.",
    "Adaptarse al entorno para el logro de los objetivos.",
    "Acelerar el ritmo en condiciones especiales.",
    "Disposición para emprender nuevos proyectos, sabiendo cómo mantener la calma en la rutina.",
    "Creatividad a la hora de enfrentar retos complejos.",
    "Estar informado frente a los cambios.",
    "Buena disposición para asumir retos.",
    "Ser metódico en lo rutinario y creativo al enfrentar retos complejos.",
    "Ser consciente al asumir riesgos."
  ],
  10: [
    "Tener buen sentido de equipo.",
    "Entusiasmo frente a los retos, los desafíos y la solución de problemas.",
    "Enfoque en la integración del equipo para la obtención de resultados.",
    "Adaptación al entorno para el logro de los objetivos.",
    "Calmado en la rutina y de ritmo rápido y enérgico en circunstancias especiales.",
    "Colaborar con los demás para avanzar en los resultados cuando sea necesario.",
    "Ser ecuánime y conciliador al hablar y al mismo tiempo firme y directo.",
    "Ser generoso con los demás, de una manera activa.",
    "Ser gentil a la hora de impulsar a los demás, para la obtención de resultados.",
    "Ser detallista y apalancarse en métodos probados en la solución de problemas.",
    "Equilibrar el ambiente activamente de forma amable, manteniendo el foco en los resultados.",
    "Cuando la circunstancia lo demandé actuar con firmeza y determinación.",
    "Ser reflexivo en situaciones de estrés y calmado en la rutina.",
    "Ser ético y leal.",
    "Poder ser autónomo en la obtención de resultados en situaciones difíciles.",
    "Ser considerado al comunicar las opiniones.",
    "Ser consciente al asumir riesgos.",
    "Saber manejar información confidencial.",
    "Saber atender las necesidades de las personas aun en situación de presión.",
    "Ser conciliador en los conflictos y asumir una posición firme, solo si eso colabora en la solución del problema.",
    "Apoyarse en las metodologías para la realización de las tareas.",
    "Tomar la iniciativa y tener una actitud creativa cuando sea necesario.",
    "Ser capaz de estar al frete de varios retos a la vez.",
    "Ser diplomático, buen escucha y estabilizador de forma activa y enérgica.",
    "Adaptarse al ritmo de los demás y estar disponible para la acción.",
    "Realizar las tareas diarias de forma rutinaria y metódica.",
    "Ser creativo a la hora de enfrentar retos complejos.",
    "Influenciar con un estilo activamente compresivo y conciliador.",
    "Estimular al equipo amablemente para la obtención de los resultados."
  ],
  11: [
    "Orientarse a la tarea y a la obtención de resultados de forma creativa.",
    "Cuidar los procesos y la calidad.",
    "Ser asertivo y firme en la comunicación.",
    "Obtener resultados rápidamente manteniendo el control de calidad.",
    "Estar en disposición de invertir tiempo para asegurar los estándares de calidad, pero beneficiando la obtención de resultados.",
    "Ser creativo y analítico.",
    "Ser realizador, enfocado en la tarea.",
    "Una visión dirigida al futuro de forma objetiva.",
    "Ideas creativas para prever y solucionar problemas.",
    "Estilo directivo matizado ligeramente por diplomacia y sensibilidad.",
    "Responder con rapidez a la hora de tomar decisiones siendo capaz de analizar, previamente, la mayor cantidad de información posible para respaldarlas.",
    "Habilidad de analizar rápidamente los pros y los contras de una solución.",
    "Asumir riesgos y ser capaz de correr con las consecuencias.",
    "Saber privilegiar la acción y el análisis.",
    "Marcar el ritmo a la hora de solucionar problemas.",
    "Influenciar a través del interés por alcanzar las metas con excelencia.",
    "Hacer las tareas rápido y con control de calidad.",
    "Apreciar el cambio, la acción, los retos y poder calcular los costos cuando el tiempo se lo permite.",
    "Una visión de los proyectos y retos tanto general como de detalles.",
    "Ser capaz de mirar el todo y las partes, haciendo énfasis en lo global.",
    "Ideas creativas e innovadoras.",
    "Capacidad para ser reservado.",
    "Ir al grano con la información clave.",
    "Capacidad para aplicar métodos eficientes para lograr los objetivos, de forma rápida y respetando los controles de calidad.",
    "Tener autoridad para decidir y empujar cambios y proyectos.",
    "Tener libertad de movimiento.",
    "Conseguir y analizar información y hechos.",
    "Enfocarse en los objetivos y en la calidad."
  ],
  12: [
    "Estar orientado a la tarea.",
    "Comunicación asertiva.",
    "Obtener resultados rápidamente cuidando de la calidad y de los procesos.",
    "Capacidad de análisis y creatividad.",
    "Impulsar la realización de las tareas con determinación.",
    "Criterio en la proyección de los logros y metas.",
    "Ideas creativas para prever y solucionar problemas.",
    "Liderar con firmeza y moderación.",
    "Agilidad en la toma de decisiones.",
    "Diligencia en la recolección de documentos e información, para respaldar con consistencia la toma de decisiones.",
    "Mantener el control del ritmo en la solución de los problemas.",
    "Enforque en alcanzar las metas con excelencia.",
    "Sentido de urgencia y destreza en las tareas, manteniendo la calidad.",
    "Prever los riesgos en situaciones de cambio.",
    "Una visión general y particular de los proyectos y retos.",
    "Considerar lo global y los detalles.",
    "Reflexión y análisis para atender las urgencias.",
    "Orientación al logro de los objetivos y automotivación para cuidar la calidad.",
    "Aplicación de métodos eficientes para lograr los objetivos, de forma rápida y respetando los controles y los procedimientos.",
    "Poder, autoridad y decisión para impulsar cambios y proyectos."
  ],
  13: [
    "Ser impulsador Impulsar del cambio.",
    "Orientación a la acción y a la tarea.",
    "Ser un realizador.",
    "Enfoque en los procesos, en la calidad y en la obtención rápida de resultados.",
    "Creatividad, imaginación, capacidad de análisis y cálculo de riesgos.",
    "Resolver los problemas, afrontar los retos y diseñar protocolos.",
    "Cuidado de las normas y de los procedimientos sin descuidar los resultados.",
    "Propuestas novedosas y originales para afrontar los problemas o los proyectos.",
    "Profesional especializado.",
    "Determinación para la obtención de los resultados.",
    "Respetar los protocolos establecidos y los estándares de calidad.",
    "Planteamiento de ideas para debatir y crear respuestas de alta calidad en la solución de los problemas.",
    "Ser detallista.",
    "Registrar los hechos y la información disponible para la toma de decisiones.",
    "Solventar situaciones imprevistas con la urgencia del caso, validando la información y los hechos.",
    "Establecer prioridades.",
    "Acciones efectivas.",
    "Análisis rápido de las situaciones para tomar decisiones enfocadas en soluciones eficientes.",
    "Disposición al cambio calculando los riesgos.",
    "Entender la productividad del negocio, manteniendo los estándares de calidad.",
    "Orden y disciplina.",
    "Resguardo del orden y la higiene en el lugar de trabajo de forma activa.",
    "Promoción de ideas creativas en beneficio de nuevos sistemas y protocolos.",
    "Conseguir sistemas más productivos o con mayor calidad.",
    "Cuidar las reglas y las normas con un cierto grado de flexibilidad.",
    "Proyecciones en la forma de establecer normas y procedimientos eficientes.",
    "Enfoque en la solución de problemas y en la producción.",
    "Análisis, precisión y precaución para la toma de decisiones.",
    "Dominio de situaciones simultaneas.",
    "Agilidad en las decisiones de rutina.",
    "Revisión y análisis de opciones para atender a los temas nuevos e importantes."
  ],
  14: [
    "Entablar relaciones armónicas y amistosas",
    "Actitudes positivas, optimistas, entusiastas y sentido del humor.",
    "Motivación e Identificación con el entorno, para el logro de los objetivos.",
    "Mente amplia y actitud receptiva.",
    "Entusiasmo, optimismo y estabilidad para el trabajo en equipo.",
    "Habilidades en la comunicación.",
    "Ser un coach natural.",
    "Relacionarse con carisma y empatía con el equipo.",
    "Generar confianza en el equipo para cumplir con los compromisos adquiridos.",
    "Confianza en las habilidades propias.",
    "Cooperación y autorreconocimiento.",
    "Claridad en el desempeño de las funciones.",
    "Motivación para ayudar a los demás",
    "Capacidad de respuestas positivas en momentos de tensión.",
    "Alto nivel de tolerancia con personas que puedan perjudicar la productividad.",
    "Habilidad natural para construir relaciones positivas.",
    "Ser sociable, complaciente, gentil y amigable.",
    "Trabajar en equipo.",
    "Ser Colaborador.",
    "Ser persuasivo, comprensivo y positivo.",
    "Expresividad en el discurso.",
    "Generar confianza para lograr apertura de los demás.",
    "Crear un ambiente positivo y agradable.",
    "Ser metódico en momentos particulares",
    "Desarrollada intuición para reconocer las actitudes de los demás.",
    "Disponibilidad y habilidad para atender y solucionar los problemas de las personas.",
    "Ser negociador imparcial en situaciones de conflicto.",
    "Sensibilidad y motivación."
  ],
  15: [
    "Enfoque en las relaciones.",
    "Identificación con las personas.",
    "Habilidad de negociar en conflictos, orientando el resultado al ganar-ganar.",
    "Ser persuasivo, comprensivo, con calidad humana.",
    "Fortalecer al equipo con integración, alegría, optimismo y estabilidad.",
    "Inspirar confianza en los demás.",
    "Permitir la visibilidad de los demás.",
    "Paciencia.",
    "Ser receptivo, empático y compasivo.",
    "Socializar con gentileza generando vínculos amistosos.",
    "Generar confianza.",
    "Colaborador.",
    "Buena disposición en situaciones de conflicto.",
    "Estimular al equipo para el logro de las metas.",
    "Desarrollar un ambiente laboral estimulante y entusiasta, cuidando los detalles en las relaciones.",
    "Tener el sentido de urgencia en momentos de presión.",
    "Ser puntual y metódico en las instrucciones, cuidando de mantener el buen ánimo.",
    "Tolerancia ante las personas conflictivas.",
    "Habilidad para construir relaciones positivas.",
    "Ser un coach natural."
  ],
  16: [
    "Entablar relaciones amistosas con el entorno.",
    "Empatía con los demás.",
    "Comprensión, persuasión, honestidad y calidad humana.",
    "Paciencia y receptividad para calmar los animo, transmitiendo tranquilidad y optimismo.",
    "Ser colaborador.",
    "Ser un coach natural.",
    "Actitud natural para la escucha y la expresión.",
    "Saber trabajar en equipo.",
    "Valorar los detalles para asumir los cambios.",
    "Promover conversaciones de temas generales, para generar confianza.",
    "Ceder espacios y atención a los demás, para que sean protagonistas.",
    "Interés por las relaciones interpersonales.",
    "Responsabilidad con las normas y reglamentos.",
    "Paciencia.",
    "Inyección de estabilidad y optimismo al equipo.",
    "Ofrecer soporte y apoyo al equipo.",
    "Confianza.",
    "Habilidades de comunicación.",
    "Socializar con amabilidad.",
    "Percepción emocional y preocupación por las necesidades de los demás.",
    "Ser detallista y entusiasta."
  ],
  17: [
    "Atender a las personas, enfocado hacia la calidad del trabajo y los procesos.",
    "Ser motivador y entusiasta.",
    "Preservar el buen clima laboral.",
    "Generar confianza y cercanía con las personas, estimulando el interés por la calidad.",
    "Identificación con las tareas, los protocolos, los procedimientos y los estándares de calidad en un ambiente amigable.",
    "Trabajo en equipo conservando las normas y los procesos.",
    "Motivar al equipo para la obtención de altos estándares de calidad.",
    "Flexibilidad de carácter.",
    "Inspirar confianza y cercanía.",
    "Analizar la información y los hechos para la toma las decisiones.",
    "Reconocimiento de las tareas.",
    "Habilidad para las relaciones públicas y diplomáticas.",
    "Puntualidad, responsabilidad y compromiso en la realización de las tareas.",
    "Relacionarse con las personas.",
    "Ser influyente e integrador.",
    "Generar vínculos laborales con los compañeros, clientes y proveedores.",
    "Tener sentido de pertenencia y colaboración con el equipo.",
    "Actitud mediadora y disciplinada para mantener las buenas relaciones.",
    "Ejercer influencia asertiva con los demás.",
    "Consideración hacia los demás.",
    "Habilidad para atender las necesidades de las personas y cuidar las relaciones.",
    "Detallista en los procesos de calidad para la ejecución de los proyectos.",
    "Ser comunicativo de los procedimientos y los estándares.",
    "Percepción global y analítica ante los problemas que se presenten.",
    "Receptividad y disponibilidad para apoyar las situaciones que se presenten.",
    "Desarrollar entusiasmo y estimulo en el equipo en la ejecución de tareas complejas.",
    "Competencia para resolver conflictos entre las personas.",
    "Honestidad en las opiniones y observaciones.",
    "Precaución y consideración en el análisis de los hechos y las informaciones para la toma de decisiones.",
    "Obtener provecho de los conflictos, para el aprendizaje y la adaptación.",
    "Habilidad para delegar haciendo supervisión y seguimiento en un ambiente amigable."
  ],
  18: [
    "Ser minucioso en el manejo de los proyectos manteniendo el cuidado de las relaciones.",
    "Manejar con equilibrio las relaciones personales, los protocolos y procedimientos establecidos.",
    "Cumplir con los plazos de entrega.",
    "Relacionarse y mediar positivamente entre las personas.",
    "Demostrar optimismo y entusiasmo sinceros.",
    "Motivar al equipo en el cumplimiento de los procedimientos.",
    "Aportar energía a los proyectos para el logro de los objetivos.",
    "Capacidad de análisis y reflexión.",
    "Ser sociable mientras mantiene el foco en la calidad.",
    "Competencias para comunicar y asignar eficientemente las normas, procedimientos y planes de acción.",
    "Ser influyente y carismático para estimular el cumplimiento de los protocolos y los plazos.",
    "Detallista en el desempeño de las tareas.",
    "Reconocer y transmitir la cultura organizacional.",
    "Ser atento y cauteloso al asumir riesgos, pero con una mentalidad abierta a los cambios.",
    "Generar confianza sin dejar de ser cauteloso.",
    "Habilidad para dirigir a los demás en sus tareas y en el cumplimiento de las normas.",
    "Asumir tareas especializadas manteniendo la motivación e integración del equipo.",
    "Analizar información de forma ágil, al momento de asumir riesgos, solucionar problemas y tomar decisiones.",
    "Comunicación articulada para transmitir los planes de acción.",
    "Competencia para promover ideas y generar influencia en áreas especializadas."
  ],
  19: [
    "Ofrecer al equipo un enfoque en el control de calidad manteniendo un buen clima laboral.",
    "Tener un buen nivel de optimismo realista.",
    "Tomar decisiones analizando la información y los hechos, considerando a todos los involucrados.",
    "Identificar las normas, procedimientos y protocolos establecidos.",
    "Comunicar eficientemente las normas, procedimientos, políticas y la cultura organizacional.",
    "Motivar con diplomacia y profesionalismo.",
    "Ser atento, cauteloso y detallista.",
    "Ser recursivo para comunicar con lógica, investigación y entusiasmo los argumentos.",
    "Alentar al equipo al logro de las metas, respetando los procesos y cuidando la calidad.",
    "Aportar profesionalismo y jovialidad al ambiente de trabajo.",
    "Ser detallista en el análisis, procedimientos y en la calidad de los proyectos manteniendo un contacto cercano y de confianza.",
    "Generar confianza y profesionalismo en el área.",
    "Habilidad natural para generar entusiasmo frente a los procedimientos.",
    "Respetar los procedimientos con amplitud e intuición.",
    "Ser metódico y meticuloso en la ejecución de los proyectos, manteniendo un ambiente amigable.",
    "Capacidad de visualización del detalle en el plan de acción.",
    "Habilidad para negociar en los conflictos orientando al equipo al ganar-ganar.",
    "Transmitir articuladamente los pasos a seguir en los planes de acción.",
    "Promover ideas e influenciar en las áreas técnicas especializadas.",
    "Ser un comunicador nato de los protocolos y la cultura corporativa."
  ],
  20: [
    "Enfoque en los detalles de los procesos.",
    "Hacer el trabajo bien desde el primer intento.",
    "Orden e higiene en el trabajo.",
    "Colaborar y trabajar en equipo.",
    "Supervisar y respetar las normas, los procedimientos y el cuidado de los detalles.",
    "Ser experto, metódico y meticuloso en el área",
    "Receptividad, con valores de lealtad y discreción.",
    "Estabilidad emocional.",
    "Tiempo para revisar y analizar información y hechos antes de actuar.",
    "Ser buen oyente.",
    "Conciliador y apaciguador en momentos de tensión y conflicto.",
    "Equilibrio en el cálculo de los riesgos.",
    "Empatía en la comunicación.",
    "Planificar los cambios.",
    "Trabajo rutinario.",
    "Perseverancia en los proyectos.",
    "Cumplir con las responsabilidades de manera consciente y cuidadosa.",
    "Moderación y autocontrol.",
    "Capacidad de predecir los obstáculos que los demás pasan por alto.",
    "Saber interpretar con análisis y lógica los diferentes discursos del personal.",
    "Disposición para debatir las ideas con objetividad y diplomacia.",
    "Ceder el protagonismo.",
    "Pensamiento crítico para la toma de decisiones y solución de problemas."
  ],
  21: [
    "Calcular los riesgos en la toma de decisiones analizando mucha documentación de forma anticipada.",
    "Ser consciente del tiempo y de los plazos.",
    "Actuar previo análisis y revisión de la información.",
    "Ser detallista y persistente en la búsqueda de información.",
    "Compromiso con el control de calidad en los procedimientos.",
    "Disfrutar del trabajo en equipo.",
    "Dedicación absoluta a la revisión de los procesos, protocolos y procedimientos, previamente a la ejecución.",
    "Considerar todas las variables involucradas en un proyecto.",
    "Exigir y autoimponerse altos estándares de calidad.",
    "Generar en la personal disposición al análisis y la lógica del detalle de la información y de los datos disponibles.",
    "Ser educado y diplomático.",
    "Capacidad de conciliación y objetividad frente a las confrontaciones.",
    "Experto en el área de acción, con elevados estándares de calidad.",
    "Ser cauteloso, metódico y controlado.",
    "Continua preparación en el mejoramiento profesional.",
    "Intuitivo y paciente.",
    "Ser buen oyente.",
    "Análisis constante de las informaciones.",
    "Ser preciso en la comunicación y en las acciones.",
    "Disponibilidad para apoyar a los compañeros.",
    "Reconocer los productos y los detalles del proceso.",
    "Capacidad de ajuste y adaptación a los ritmos del entorno.",
    "Orientación a la excelencia en los procesos."
  ],
  22: [
    "Mantener altos estándares de calidad.",
    "Apego a los protocolos, normas y procedimientos establecidos.",
    "Ser consistente y consciente.",
    "Responsabilidad con los tiempos y los plazos",
    "Paciencia y discreción.",
    "Reconocer las jerarquías.",
    "Trabajar bajo supervisión.",
    "Guías e instrucciones claras.",
    "Coordinación y seguimiento en los proyectos, aplicando los controles de calidad.",
    "Análisis, precisión y exactitud.",
    "Aplicar análisis y metodologías a la solución de los problemas.",
    "Ser cauteloso y estable.",
    "Honestidad y precisión en la presentación de los hechos.",
    "Incentivar el mejoramiento profesional.",
    "Habilidad de estabilizar al equipo mediante la conciliación e imparcialidad.",
    "Ser buen oyente.",
    "Competencias en el análisis y la crítica constructiva.",
    "Ser experto en el área, con altos estándares de calidad.",
    "Analizar los hechos y las informaciones para la toma de decisión.",
    "Calcular y anticipar los riesgos con fundamentos.",
    "Autocrítica.",
    "Motivar a los demás para atender los detalles y los datos disponibles.",
    "Fomentar la investigación y el análisis de la información",
    "Centrarse en los procesos y en los métodos.",
    "Persuadir a los demás a través de los hechos, la lógica y la objetividad.",
    "Responsabilidad y compromiso con las tareas.",
    "Disciplina, diplomacia y buenos modales.",
    "Control de las emociones."
  ],
  23: [
    "Enfoque en los resultados, preservando las necesidades de las personas.",
    "Ser influyente y persuasivo.",
    "Habilidades naturales para la solución de los problemas.",
    "Capacidad de argumentar en beneficio del buen clima, en los debates.",
    "Capacidad de dirigir al grupo al logro de los resultados exitosamente.",
    "Generar un clima entusiasta y optimista.",
    "Responder con firmeza y aplomo o gentileza, dependiendo de la situación.",
    "Ser ejemplo de optimismo y capacidad.",
    "Generar el interés de las personas en los proyectos, logrando enfocarlos en la solución de los problemas.",
    "Orientación al logro de los resultados.",
    "Capacidad de ajustarse a las demandas de un proyecto, ingresando en cualquiera de sus etapas, destacándose en la obtención de los logros.",
    "Apoyar a las personas en la solución de los problemas, sin perder el foco en los resultados.",
    "Delegar con firmeza y gentileza",
    "Agilidad en los ritmos para el logro de resultados.",
    "Aplicar naturalmente el sentido de la urgencia para la obtención de los resultados.",
    "Distinguir las oportunidades en los proyectos para el logro de resultados efectivos.",
    "Demostrar comprensión y firmeza si la situación lo demanda, con paciencia y amabilidad.",
    "Responsabilidad en las posiciones que le otorguen autoridad.",
    "Movilizador del cambio para el logro de los objetivos."
  ],
  24: [
    "Ser ambicioso en las metas a alcanzar.",
    "Capacidad de influenciar a las personas.",
    "Habilidades naturales de liderazgo.",
    "Ser enfático, firme y determinado.",
    "Asumir retos y desafíos.",
    "Autoconfianza para resolver problemas y alcanzar los resultados.",
    "Mantener la cohesión en el equipo.",
    "Buscar nuevos retos y proyectos.",
    "Demostrar interés en las relaciones y necesidades de las personas.",
    "Iniciativa y liderazgo.",
    "Habilidades para comunicar el proyecto con visión global.",
    "Encarar los riesgos y los retos.",
    "Estimar las relaciones personales.",
    "Mantener posición en situaciones de tensión.",
    "Habilidad para manejar las presiones en beneficio de los objetivos.",
    "Responsabilidad y compromiso en la competencia para la conquista de nuevos espacios.",
    "Firmeza con comprensión y gentileza si la situación lo demanda.",
    "Considerar la políticas y sistemas de la empresa en la procura de los resultados.",
    "Cooperación y liderazgo para el logro de los resultados.",
    "Estimular a las personas a dar un mayor rendimiento en beneficio de los objetivos.",
    "Enfoque en la obtención de los resultados, cuidando de las relaciones.",
    "Orientar la solución de los conflictos entre las personas.",
    "Estimular la motivación y el optimismo para alcanzar el éxito.",
    "Visión global y general de los proyectos.",
    "Mantener el cumplimiento de sistemas y métodos, mientras estos favorezcan el logro de los objetivos.",
    "Manejo de ambientes dinámicos, cambiantes y rápidos.",
    "Dinamismo en la toma de decisiones.",
    "Alinear al equipo en el progreso y el logro de los resultados.",
    "Atención a las necesidades de las personas, en beneficio del logro."
  ],
  25: [
    "Estar centrado en la obtención de los resultados, maniobrando los retos.",
    "Capacidad de adaptación a los ritmos del entorno, para el logro de los objetivos.",
    "Calibrar las necesidades del proyecto, para ajustar los ritmos.",
    "Ser paciente en condiciones normales y con sentido de urgencia en situaciones especiales.",
    "Colaborar con los demás para avanzar en los resultados, en situaciones de presión.",
    "Integración con el equipo.",
    "Firmeza en las posiciones en situaciones de tensión.",
    "Mantener el buen clima laborar, considerando las necesidades de los demás.",
    "Comunicación directa y firme pero amable.",
    "Tener actitudes de consideración, ecuanimidad, optimismo y conciliación de forma estratégica.",
    "Habilidad para dirigir a las personas a la obtención del logro de forma amigable.",
    "Atención y seguimiento a los compromisos adquiridos.",
    "Buen manejo del clima laboral, equilibrando las emociones del ambiente.",
    "Transmitir las opiniones con firmeza y argumento.",
    "Ser metódico y detallista en la solución de los problemas cotidianos.",
    "Innovación y creatividad frente a los retos y desafíos.",
    "Demostrar integración con el equipo.",
    "Saber elegir de forma estratégica entre los resultados de un proyecto o las necesidades de las personas.",
    "Compromiso y responsabilidad con las tareas y metas.",
    "Visión global y de detalle para abordar los problemas del proyecto.",
    "Atender varios proyectos al mismo tiempo.",
    "Demostrar innovación y creatividad.",
    "Lidiar con las rutinas y metodologías establecidas.",
    "Influenciar estabilidad en los momentos de tensión.",
    "Conectar con el equipo con amistosa determinación para la obtención de los resultados.",
    "Capacidad de adaptación al entorno.",
    "Agilidad y energía en el sentido de urgencia.",
    "Disposición para emprender nuevos y variados proyectos.",
    "Creatividad frente a retos complejos.",
    "Soluciones efectivas en los conflictos de las personas.",
    "Anticipase a la información para los cambios con buena disposición.",
    "Enfrentar los retos complejos con creatividad.",
    "Asumir los riesgos."
  ],
  26: [
    "Aplicar métodos efectivos para la solución de problemas.",
    "Creatividad en la aplicación de los sistemas comprobados.",
    "Enfrentar retos y desafíos.",
    "Habilidades naturales de liderazgo.",
    "Ser generoso con los demás de una manera activa.",
    "Orientación al logro a través de las personas.",
    "Metas claras para alcanzarlas a través de la gente, en un buen clima laboral.",
    "Actuar con firmeza y determinación.",
    "Interés en el bienestar de las personas.",
    "Autoconfianza en la resolución de problemas y resultados.",
    "Adaptación al entorno para el logro de los objetivos.",
    "Comunicación firme, directa y amable.",
    "Consideración y ecuanimidad en el trato.",
    "Habilidad para influenciar a los demás, para la obtención de los resultados.",
    "Ética y lealtad.",
    "Comunicar las opiniones con firmeza y sentido de reflexión.",
    "Integración e interacción con el equipo de forma activa.",
    "Demostrar interés en las necesidades y relaciones de las personas.",
    "Comunicar el proyecto con una visión global.",
    "Adecuarse a los retos y riesgos que se presenten.",
    "Innovación y creatividad en las tareas rutinarias.",
    "Determinación cuando la situación lo demanda, demostrando comprensión.",
    "Reconocer las políticas y sistemas de la empresa.",
    "Cooperación con el equipo para el logro de los resultados.",
    "Tomar posición en situaciones de tensión.",
    "Habilidad para disminuir los momentos de tensión en beneficio del logro de los objetivos.",
    "Ofrecer soporte al personal para que superen las brechas de rendimiento, en función de las metas.",
    "Adecuarse al ritmo de trabajo de los demás para beneficiar los resultados.",
    "Atender los detalles del proyecto, manteniendo el buen clima laboral.",
    "Saber elegir los métodos que garanticen, lo más rápido posible, los resultados o la solución del problema.",
    "Motivación al logro sin perder el foco en las necesidades del equipo."
  ],
  27: [
    "Agilidad en la toma de decisiones y en los resultados.",
    "Liderazgo e influencia.",
    "Dinamismo y competencia en las iniciativas.",
    "Liderazgo en pro de la innovación conservando los estándares de calidad.",
    "Influenciar y motivar a las personas con actitudes flexibles.",
    "Mantener el aplomo incluso en ambientes de tensión.",
    "Estimular al equipo para trabajar con ambición para el logro de las metas y el cuidado de la calidad.",
    "Capacidad de atender con eficiencia varios proyectos simultáneamente.",
    "Mantener el nivel de entusiasmo en cada estatus del proyecto.",
    "Habilidad para colocar las circunstancias a favor de los resultados.",
    "Dominio del discurso.",
    "Enfocado en la calidad de los procesos desde el inicio del proyecto.",
    "Comunicación eficiente hacia los distintos grupos.",
    "Transmisión del mensaje con seguridad, confianza y carisma.",
    "Iniciativa para conquistar nuevos mercados.",
    "Generar confianza, crear redes, estrechar lazos en un ambiente de resultados y calidad.",
    "Impulsar al equipo al logro de los resultados manteniendo los protocolos establecidos.",
    "Liderazgo positivo para el logro de los objetivos.",
    "Inyectar fuerza y entusiasmo al equipo.",
    "Capacidad de adaptación a las situaciones que se presenten, en la búsqueda de los objetivos.",
    "Motivación y persuasión al equipo para el logro de los resultados.",
    "Estar siempre atento al proyecto, desde el inicio hasta el fin, cuidando del seguimiento y de los estándares de calidad para alcanzar los objetivos."
  ],
  28: [
    "Ser pionero y líder, manteniendo los estándares de calidad.",
    "Mantener el enfoque en el objetivo motivando al equipo a la realización de las tareas.",
    "Destreza en la toma de decisiones.",
    "Rapidez en el análisis de la información relevante para poner en marcha las tareas.",
    "Habilidad para sacar provecho de las circunstancias, para conseguir resultados.",
    "Capacidad de oratoria.",
    "Sentido de urgencia en el análisis de la información y en la toma de decisiones.",
    "Ser experto e innovador en la solución de los problemas.",
    "Dirigir y estimular al equipo en la orientación al logro de los objetivos, con excelencia.",
    "Tener una visión global del proyecto cuidando de los detalles.",
    "Reconocimiento a las capacidades de las personas frente a los cambios.",
    "Valorar al personal que mantiene el cuidado de los estándares de calidad, obteniendo buenos resultados.",
    "Creatividad e innovación en los métodos, sistemas, protocolos, para mejorar el rendimiento y la calidad.",
    "Proyectar liderazgo positivo al personal.",
    "Mantener los estándares de calidad, analizando otras opciones disponibles.",
    "Considerar las políticas y protocolos de la empresa.",
    "Espíritu de superación.",
    "Construcción de redes de contactos claves.",
    "Ser recursivo en la solución de los problemas, con buen manejo de las relaciones.",
    "Proyectar en los demás capacidades para solucionar y manejar situaciones.",
    "Transmitir actitudes optimistas al equipo.",
    "Lograr objetivos dentro de los plazos con los recursos disponibles.",
    "Tomar riesgos sin perder de vista la calidad en el proyecto.",
    "Cumplir con los estándares de calidad en beneficio del logro de los objetivos.",
    "Considerar probar nuevos procedimientos, dinámicos y rápidos, favoreciendo siempre la calidad.",
    "Capacidad de realizar diversas tareas, manteniendo el foco en lo importante.",
    "Desenvolverse en ambientes dinámicos y cambiantes.",
    "Dirigir con motivación al personal para mantener el ritmo y los niveles de calidad para el logro de los resultados.",
    "Delegar responsabilidades con optimismo.",
    "Competencia con responsabilidad.",
    "Negociar en situaciones de conflictos, manteniendo la calidad del proyecto.",
    "Autoconfianza y comunicación efectiva.",
    "Capacidad de persuadir e influenciar a los grupos."
  ],
  29: [
    "Estar orientado principalmente en la tarea.",
    "Capacidad de influenciar a las personas a través del entusiasmo y dinamismo, manteniendo el foco en la obtención de resultados de calidad.",
    "Comunicación asertiva.",
    "Ser creativo y analítico, para motivar al equipo en la obtención de resultados.",
    "Ser visionario con enfoques reales.",
    "Habilidad creativa para prever y solucionar los problemas, mejorar los protocolos y procedimientos.",
    "Diplomacia y sensibilidad en el trato con los demás.",
    "Respuestas rápidas en la toma de decisiones.",
    "Repasar la mayor cantidad de información para el respaldo de las decisiones.",
    "Marcar el ritmo en la solución de los problemas.",
    "Orientado a la excelencia en el logro, influenciando a las personas a alcanzar las metas.",
    "Dedicación en el control de la calidad en las tareas, para realizarlas con rapidez.",
    "Mantener el foco en las tareas y los resultados.",
    "Calcular los riesgos en situaciones de cambio.",
    "Visión holística de los proyectos, desde lo general hasta lo particular.",
    "Percepción del todo y las partes, lo global y los detalles.",
    "Orientación constante hacia el rendimiento y la calidad de forma activa.",
    "Mantener un buen clima laboral.",
    "Inyectar energía y dinamismo al equipo.",
    "Compartir la Información de manera estratégica.",
    "Capacidad de automotivación.",
    "Aplicación de métodos efectivos, respetando los controles de calidad para el logro de los objetivos.",
    "Determinación y firmeza para la toma de decisiones en situaciones de cambio."
  ],
  30: [
    "Ser realizador.",
    "Orientación hacia los resultados, investigando nuevos y mejores procedimientos para alcanzarlos.",
    "Enfocar los cambios con innovación y calidad.",
    "Mantener el entusiasmo en el equipo.",
    "Alcanzar el logro de los resultados.",
    "Asumir y promover los cambios.",
    "Mantener los estándares de calidad de manera activa.",
    "Estar enfocado en el rendimiento y en la calidad.",
    "Crear un buen clima laboral.",
    "Detalle e innovación en la solución de los problemas y en la actualización de los procedimientos.",
    "Capacidad para detectar información clave para actuar.",
    "Atender muchos frentes al mismo tiempo.",
    "Ser entusiasta y dinámico.",
    "Alineación con las tareas y los resultados.",
    "Determinación y firmeza en las posiciones, sin descuidar el bienestar de las personas.",
    "Análisis y detalle de la información y los hechos con sentido de urgencia.",
    "Habilidad para persuadir.",
    "Ser ejemplo de capacidad para resolver los problemas.",
    "Balance en el logro de los resultados manteniendo los estándares de calidad.",
    "Aportar ideas creativas para la solución de los problemas.",
    "Experto en el área.",
    "Conocimiento e investigación de las opciones antes de actuar.",
    "Comunicación asertiva.",
    "Estimular al equipo a mantener el ritmo en el proyecto y a cuidar los protocolos y la calidad.",
    "Visión de futuro, de forma objetiva y con un adecuado nivel de optimismo.",
    "Habilidad de analizar rápidamente los pros y los contras de una solución.",
    "Proactividad.",
    "Resolver en situaciones de cambio, asumiendo retos y calculando los costos.",
    "Visión global de los proyectos, considerando el todo y las partes.",
    "Vocación por la excelencia en la obtención rápida de resultados a través de la gente.",
    "Estilo analítico, creativo e innovador en beneficio de la calidad."
  ],
  31: [
    "Enfoque en la obtención de los resultados, manteniendo la calidad.",
    "Enfrentar los retos sin perder el sentido del trabajo en equipo.",
    "Estar orientado al resultado en las tareas, cuidando de los detalles.",
    "Adaptación al entorno para el logro de los objetivos.",
    "Agilidad y energía en el cuidado de los procedimientos, para la obtención de resultados.",
    "Paciencia y detalle en circunstancias normales y rapidez de respuesta en situaciones especiales.",
    "Colaboración con los demás para mantener la calidad y buenos resultados.",
    "Comunicación firme y directa.",
    "Consideración y diplomacia hacia los demás.",
    "Generosidad activa hacia los demás.",
    "Ordenar con gentileza para la obtención de resultados.",
    "Compromiso y coordinación en el seguimiento y ejecución de las tareas.",
    "Aplicar tendencias innovadoras en los sistemas establecidos.",
    "Exigir mayor rendimiento manteniendo los estándares de calidad.",
    "Equilibrio y armonía en los ritmos de actuación.",
    "Ética y lealtad en situaciones difíciles.",
    "Comunicar las opiniones con firmeza, luego de reflexionar.",
    "Integración con el equipo.",
    "Análisis y reflexión en las informaciones y los hechos disponibles.",
    "Administrar disciplina frente a las desviaciones.",
    "Atender varios frentes simultáneamente.",
    "Tener una visión global y de detalle, sobre los proyectos.",
    "Proyectar con visión de futuro, tomando en cuenta las referencias y métodos probados.",
    "Ser curioso, innovador y creativo, y al mismo tiempo apreciar los métodos y la estabilidad.",
    "Adaptación a los demás cuando es estratégico.",
    "Estar listo para la acción, calculando los riesgos objetivamente.",
    "Actitud creativa y analítica para enfrentar retos complejos."
  ],
  32: [
    "Enfoque en la tarea y en los resultados.",
    "Trabajar en equipo.",
    "Aceptar retos.",
    "Comunicación efectiva.",
    "Orientación a resultados con estándares de calidad, manteniendo satisfechas las necesidades de las personas.",
    "Adaptación con el entorno para el logro de los resultados.",
    "Colaborar con los demás para avanzar en los resultados y controlar la calidad.",
    "Evaluar al personal involucrado en el rendimiento del negocio.",
    "Estilo de comunicación equilibrado.",
    "Consideración y diplomacia en el trato, siendo directo y firme.",
    "Actitudes generosas con los demás de manera activa.",
    "Habilidad de inducir con gentileza a los demás hacia la obtención de resultados de calidad.",
    "Promover estabilidad y calidad para la obtención de los resultados.",
    "Atención a los compromisos asumidos para la coordinación u ejecución de las tareas.",
    "Cumplir con los plazos de entrega.",
    "Actitud considerada y amable, apoyando el buen clima laboral, si es estratégico para el logro de los objetivos.",
    "Aptitudes pedagógicas para enseñar a los demás a convertir conceptos y teoría en soluciones prácticas y aplicables.",
    "Pensamiento crítico, analítico y metódico, con creatividad e innovación.",
    "Ser innovador en cuanto a los sistemas establecidos.",
    "Incrementar el rendimiento con buenos estándares de calidad.",
    "Manejar eficientemente ritmos contrastantes.",
    "Ética y lealtad en las situaciones adversas para la toma de decisiones.",
    "Análisis y reflexión de las informaciones para una comunicación enérgica y firme de las opiniones.",
    "Integración y participación con el equipo.",
    "Administrar disciplina frente a las desviaciones.",
    "Capacidad para atender varios retos a la vez.",
    "Visión global y detallada sobre los proyectos.",
    "Proyectar con visión de futuro, considerando las referencias y métodos probados.",
    "Agilidad para analizar la información y hechos disponibles para la toma de decisiones.",
    "Valorar lo importante para las decisiones puntuales.",
    "Ser curioso, innovador y creativo, y al mismo tiempo apreciar los métodos y la estabilidad.",
    "Agregar valor de manera original a la solución de los problemas.",
    "Lograr un buen clima laboral, bajo las premisas de la calidad.",
    "Dar reconocimiento a las personas por enfrentar retos, analizar y solucionar problemas.",
    "Capacidad de adaptación para trabajar en equipo.",
    "Entrar en acción, calculando los riesgos objetivamente.",
    "Tener una actitud creativa y analítica a la hora de enfrentar retos complejos."
  ],
  33: [
    "Integración al equipo de trabajo para la obtención de resultados de calidad.",
    "Orientación hacia los resultados.",
    "Adaptación al ritmo del entorno para logro de los objetivos y la calidad.",
    "Mantener la calma, ser paciente y colaborador, en situaciones de tensión para avanzar en los resultados.",
    "Comunicación directa y firme sin involucrar emociones.",
    "Actitudes conciliatorias, ecuánimes y consideradas hacia los demás.",
    "Ser de generoso con los demás de una manera activa.",
    "Enfocar al grupo hacia el logro de las tareas con calidad.",
    "Habilidad para inducir, con gentileza, al equipo en la obtención de los resultados con calidad.",
    "Hacer el seguimiento a los compromisos adquiridos.",
    "Firme frente a la adversidad sin perder de vista los protocolos.",
    "Reflexionar con anticipación las opiniones, para transmitirlas con energía y firmeza.",
    "Ser detallista, analizador y metódico en la solución de problemas rutinarios.",
    "Asumir los nuevos retos con creatividad e innovación.",
    "Respaldar al equipo de trabajo de forma activa, para mantener la integración.",
    "Evaluar las demandas de las situaciones para la toma de decisión.",
    "Saber escoger entre la obtención de resultados o las necesidades de las personas dependiendo de la situación.",
    "Administrar disciplina frente a las desviaciones.",
    "Responsabilidad con las tareas y las metas.",
    "Abordar el proyecto con una visión global de los detalles.",
    "Capacidad de atender varios frentes simultáneamente.",
    "Lidiar con las rutinas de forma curiosa, innovadora y creativa.",
    "Manejar adecuadamente las metodologías y procedimientos establecidos.",
    "Dirigir a las personas al punto de estabilización, en momento de tensión.",
    "Conectar con el equipo de manera enérgica y amigable, para la obtención de los resultados y cuidado de la calidad.",
    "Identificación con el entorno para el logro de los objetivos.",
    "Sentido de urgencia en condiciones especiales, con agilidad y firmeza.",
    "Adaptación al trabajo en equipo.",
    "Disposición para armonizar la rutina con el emprendimiento de nuevos proyectos, creativamente.",
    "Enfrentar retos complejos con creatividad y método.",
    "Ante situaciones de cambio, analizar con antelación la información y los hechos",
    "Buena disposición para asumir los retos.",
    "Consciencia al asumir los riesgos."
  ],
  34: [
    "Habilidad para manejar los ritmos a la hora de enfrentar los problemas.",
    "Conciliar los ritmos para el logro de los resultados a tiempo, priorizando la calidad.",
    "Capacidad de análisis y reflexión en la operatividad tanto en las rutinas como en situaciones especiales.",
    "Enfoque en las tareas, en los resultados y en la calidad, asumiendo los retos que se presentan.",
    "Alcanzar los objetivos propuestos, coordinando que se mantenga la calidad.",
    "Trabajar con el equipo la estrategia del logro de los resultados.",
    "Considerar las necesidades de las personas, en el alcance de los objetivos y la calidad.",
    "Adaptación al entorno para el logro de los resultados.",
    "Colaborar con los demás en beneficio de los resultados y de la calidad.",
    "Comunicación equilibrada, firme y directa, con diplomacia.",
    "Profesional especializado.",
    "Controlar situaciones complejas con sentido de urgencia, analizando la información y los hechos disponibles.",
    "Actuar con rapidez.",
    "Comunicar con argumentos sólidos, las opiniones con actitud enérgica y firmeza.",
    "Especial interés en la solución de problemas, en la innovación de los procedimientos y en las ideas creativas.",
    "Implementar con innovación los nuevos protocolos para mejores rendimientos y estándares de calidad.",
    "Probar varias opciones de abordaje, frente a nuevos problemas y retos.",
    "Dar seguimiento a las promesas y compromisos.",
    "Coordinar la ejecución de las tareas, para controlar los tiempos y la calidad.",
    "Capacidad de autodirección para el logro de las metas.",
    "Consciencia de los plazos de entrega.",
    "Equilibrio y armonía en los distintos climas y ambientes laborales.",
    "Pensamiento crítico, analítico y metódico.",
    "Creatividad e innovación en las ideas.",
    "Respaldar al equipo para mantener la integración.",
    "Administrar disciplina frente a las desviaciones.",
    "Habilidad para estar atento a varios retos a la vez.",
    "Visión global y de detalle sobre los proyectos.",
    "Proyectar con visión de futuro, tomando en cuenta los procedimientos y los protocolos exitosos.",
    "Capacidad de analizar rápido la información y hechos disponibles, antes de tomar una decisión.",
    "Enfocar lo importante a la hora de decidir.",
    "Ser curioso, innovador y creativo, con sentido crítico y objetivo.",
    "Habilidad para añadir valor agregado a la solución de los problemas.",
    "Mantener el sentido de urgencia, de forma innovadora, conservando los estándares de calidad.",
    "Estar listo para la acción, calculando los riesgos objetivamente.",
    "Ser creativo y analítico para enfrentar retos complejos."
  ],
  35: [
    "Optimismo y buen sentido del humor.",
    "Enfrentar los obstáculos y buscar superarlos.",
    "Resolver los problemas de forma innovadora.",
    "Habilidad para resolver los conflictos entre las personas.",
    "Influir sobre las personas con carisma y firmeza.",
    "Generar y proyectar confianza.",
    "Integración al equipo.",
    "Habilidad para relacionarse, conocer gente nueva y mantener relaciones cercanas.",
    "Facilidad para trabajar en ambientes informales.",
    "Relacionarse y sociabilizar.",
    "Aplicar la intuición para influir y empujar a los demás en la obtención de los resultados.",
    "Capacidad de adaptación al ambiente.",
    "Firmeza en ambientes exigentes.",
    "Enfoque en las personas, las relaciones y la obtención de los resultados a través del equipo.",
    "Involucrarse en proyectos y lograr resultados, en donde el foco principal sean las personas.",
    "Liderazgo natural para dirigir al equipo.",
    "Habilidad para relacionarse con empatía hacia los demás.",
    "Desempeñar el cargo con responsabilidad y autoridad.",
    "Ser controlado, directo y firme en situaciones desfavorables.",
    "Ser auténtico, espontáneo y demostrativo en condiciones normales.",
    "Involucrarse en roles claves dentro de los proyectos.",
    "Habilidad para comunicarse de una forma precisa, amable, justa y generosa.",
    "Visión global de los proyectos sin perder los detalles.",
    "Motivar de forma natural a los demás e influenciar a la gente sobre las ideas.",
    "Demostrar disposición a ayudar a los demás, generando confianza.",
    "Confiar en las habilidades de los demás.",
    "Ser equilibrado, firme y enérgico tanto en situaciones favorables como desfavorables.",
    "Consciencia del impacto de las decisiones en los demás.",
    "Resaltar los puntos en común, en lugar de las diferencias.",
    "Construir buenas relaciones en el equipo.",
    "Disfrutar de la compañía de las personas.",
    "Lograr de forma activa, que las personas se sientan a gusto.",
    "Comunicación de manera amigable, amable y también de forma argumentativa cuando es necesario.",
    "Dar las instrucciones con detalle.",
    "Ser ágil y dinámico en la toma de decisiones.",
    "Habilidad de planificar, a gran escala, con ideas novedosas.",
    "Pionero a la hora de solucionar problemas."
  ],
  36: [
    "Habilidad para las relaciones y conocer nuevas personas.",
    "Ser buen oyente y comprensión de las partes involucradas.",
    "Escuchar todas las opciones para la toma de decisión.",
    "Tener paciencia y sentido del humor.",
    "Ser considerado con las personas.",
    "Motivar a las personas a participar y a opinar.",
    "Ofrecer consejo a las personas.",
    "Enfoque en las personas de forma activa.",
    "Estar orientado hacia las relaciones, la productividad y el rendimiento",
    "Colaborar y apoyar al equipo.",
    "Habilidad de oratoria y para motivar.",
    "Habilidad como coach o entrenador.",
    "Mantener el equilibrio en los ritmos de trabajo.",
    "Tener respeto hacia los demás.",
    "Generar el buen humor entre la gente.",
    "Hacer amena las rutinas del trabajo.",
    "Mantener un ambiente entusiasta.",
    "Persistente en los proyectos profesionales.",
    "Ser positivo a la hora de negociar los conflictos entre las personas.",
    "Lograr las metas a través de la gente y del apoyo al equipo.",
    "Manejar bien tanto los grupos pequeños como los grupos grandes.",
    "Actuar bien con los conocidos como con los extraños.",
    "Comunicación con el equipo y con los demás.",
    "Saber comunicar las sugerencias y opiniones, sabiendo ser firme con amabilidad cuando se requiera.",
    "Capacidad de escucha y de comprensión.",
    "Habilidad para orientar a los demás.",
    "Entusiasmar al equipo en el logro de los objetivos.",
    "Motivar a los demás con optimismo y energía.",
    "Ser respetuoso y sincero.",
    "Inspirar confianza para atraer gente nueva y relaciones.",
    "Capacidad de influenciar a los demás.",
    "Generar comprensión con calidez, sinceridad, receptividad y apertura."
  ],
  37: [
    "Saber enfrentar los obstáculos y buscar superarlos.",
    "Apoyar a las personas para afrontar los obstáculos.",
    "Usar los recursos que se tienen frente a los retos.",
    "Resolver los problemas con innovación.",
    "Habilidad para resolver los conflictos entre las personas.",
    "Seguridad y dinamismo.",
    "Ser agente de cambio.",
    "Ser decidido, optimista y ambicioso.",
    "Capacidad de intuición.",
    "Amabilidad y entusiasmo en las relaciones.",
    "Influencia en las personas con firmeza y encanto.",
    "Generar confianza bilateralmente.",
    "Tener sentido de urgencia.",
    "Buscar agilizar los resultados.",
    "Actuar en ambientes de cambio y dinámicos.",
    "Seguir el método y los procesos para la obtención de los resultados.",
    "Participar en la toma de decisiones.",
    "Habilidad de obtener resultados a través de la gente.",
    "Manejar con eficiencia al equipo y sus colaboradores.",
    "Convicción en las ideas.",
    "Alto nivel de exigencia, con naturalidad y sensibilidad hacia las personas.",
    "Ser dinámico, activo y colaborador.",
    "Influenciar y motivar a las personas.",
    "Influir con persuasión y amabilidad.",
    "Ser una persona firme, determinante y encantadora.",
    "Habilidad para aproximarse de diferentes maneras a las personas.",
    "Visión orientada hacia el futuro de forma creativa.",
    "Cuidar las tradiciones cuando favorezca los resultados.",
    "Ser multitasking.",
    "Adaptación al entorno según la ocasión.",
    "Facilidad para proyectar una imagen amigable, receptiva y dinámica."
  ],
  38: [
    "Habilidad para relacionarse y conocer gente nueva.",
    "Mantener relaciones cercanas.",
    "Ser arriesgado y competitivo en los proyectos, sin descuidar las necesidades de la gente.",
    "Matizar la energía con la sensibilidad personal.",
    "Delegar responsabilidades, generando un buen clima organizacional.",
    "Ejercer la autoridad con amabilidad.",
    "Ser firme y directo de forma amable.",
    "Comunicar con fluidez y elocuencia.",
    "Habilidad para comunicar, expresar y persuadir.",
    "Motivar e influenciar al equipo.",
    "Ser jovial, alegre y optimista.",
    "Transmitir firmeza y franqueza en momentos de tensión.",
    "Habilidad para influenciar con encanto y amabilidad.",
    "Ser sensible, perceptivo y leal.",
    "Atender varios proyectos a la vez.",
    "Priorizar, sin dejar de atender las necesidades de la gente.",
    "Mantener el equilibrio en los ritmos.",
    "Saber tomar la iniciativa, tanto en lo laboral como en lo personal.",
    "Vocación de servicio.",
    "Actitud empática y ser buen oyente.",
    "Expresar confianza a los demás con sinceridad.",
    "Colaboración con las personas.",
    "Tener buena disposición con los demás.",
    "Apoyar al equipo en el esfuerzo de alcanzar las metas y obtener resultados.",
    "Construir una red de contactos claves."
  ],
  39: [
    "Enfoque en la obtención de resultados de calidad a través de la gente.",
    "Habilidad de obtener resultados a través del equipo.",
    "Liderazgo responsable.",
    "Seguridad en sí mismo.",
    "Ser desenvuelto en muchos escenarios con diversos niveles jerárquicos.",
    "Integración al equipo y a los ambientes que se presentan.",
    "Mantener los niveles de rendimiento con altos estándares.",
    "Habilidad para formación y orientación de las personas hacia la calidad y los resultados.",
    "Motivar a los demás a la realización de las tareas y logro de los objetivos.",
    "Capacidad de formación para alcanzar buenos niveles profesionales.",
    "Atención a los resultados y la calidad.",
    "Estimular a las personas a seguir mejores prácticas.",
    "Conocer la psicología de las personas y del equipo.",
    "Ejercer el liderazgo para estimular e influenciar.",
    "Transmitir confianza.",
    "Ser optimista y entusiasta.",
    "Capacidad de delegar.",
    "Habilidad para comunicar hacia grandes audiencias.",
    "Transmitir calidez y energía.",
    "Capacidad de establecer los limites en grupos grandes.",
    "Autocontrol.",
    "Capacidad de convocatoria.",
    "Tener un amplio campo de influencia.",
    "Dar soporte a diversidad de personas.",
    "Habilidad para formar vínculos y comunicar las ideas.",
    "Ser condescendiente con firmeza en los momentos de presión.",
    "Ser firme con capacidad para relajarse según la ocasión.",
    "Generar un buen clima para delegar y ejercer la autoridad.",
    "Habilidad para conseguir rendimiento efectivo en los demás.",
    "Mantener el rendimiento en los proyectos desde el inicio hasta el final.",
    "Ritmo dinámico para obtener resultados inmediatos",
    "Adaptación al cambio.",
    "Lograr la colaboración y cooperación del grupo.",
    "Liderazgo orientado a la realización.",
    "Ser creativo e innovador para abordar los problemas y alcanzar los estándares de calidad.",
    "Fomentar clima de entusiasmo.",
    "Capacidad de atender diversas actividades y proyectos.",
    "Influenciar al equipo hacia el cumplimiento de los objetivos.",
    "Capacidad para que la gente se alinee con los objetivos.",
    "Flexibilidad según la situación.",
    "Rodearse de profesionales.",
    "Mantener una buena imagen y preparación, para proyectar autoridad y experticia."
  ],
  40: [
    "Habilidad para impulsar procesos complejos y proyectos de envergadura.",
    "Liderazgo efectivo",
    "Enfoque en la obtención de los resultados de calidad a través de las personas.",
    "Responsabilidad en las tareas.",
    "Equilibrio en la dedicación a las personas y las tareas.",
    "Llevar un ritmo de trabajo rápido, sin atropellamientos.",
    "Capacidad de análisis con sentido de urgencia en la toma de decisiones.",
    "Ser gentil y diplomático con sentido de humor.",
    "Habilidad para relajar los ambientes tensos.",
    "Tomar decisiones rápidas en condiciones favorables.",
    "Analizar de forma activa bajo presión.",
    "Mantener la atención en los estándares de calidad y en el trabajo en equipo.",
    "Generar motivación por el logro de resultados excelentes.",
    "Demostrar confianza en las habilidades del equipo.",
    "Manejar mucha información ocasionalmente.",
    "Análisis de la información y los hechos para la toma de decisiones",
    "Identificación con los protocolos de forma dinámica.",
    "Seguimiento a los estándares de calidad para el logro de los resultados.",
    "Motivar al equipo a lograr las metas cuidando la calidad y los procedimientos.",
    "Orientar con éxito a la gente en las tareas claves de los proyectos.",
    "Preparación profesional para enfrentar los retos y los desafíos.",
    "Negociar los conflictos, atendiendo las necesidades de las personas.",
    "Sensibilizar el clima laboral hacia la calidad y el rendimiento",
    "Procurar un ambiente estimulante, competente, de integración y de logros.",
    "Saber manejar adecuadamente los sentimientos propios y los de los demás.",
    "Expresar sentimientos positivamente.",
    "Beneficiar el logro de los resultados y la integración del equipo.",
    "Estimular al equipo para enfrentar los obstáculos con sana competencia.",
    "Supervisar los procesos con meticulosidad y consciencia.",
    "Aplicar creatividad en los protocolos de calidad.",
    "Transmitir optimismo a la gente en la obtención de los resultados de calidad.",
    "Asumir los riesgos con cautela, sin detenerse.",
    "Saber manejar al equipo cuando se trabaja bajo presión.",
    "Mantener el mejor rendimiento del equipo, en un buen clima laboral.",
    "Planificar con detalle y metodología sistémica.",
    "Comunicar con eficiencia lo planes y proyectos del equipo."
  ],
  41: [
    "Ser profesional en el área y manejar con habilidad a las personas.",
    "Enfocar con claridad los procesos de control de calidad.",
    "Generar confianza y motivación en el equipo",
    "Mantener orientado al equipo en el cuidado de los detalles.",
    "Mantener el enfoque del equipo en la calidad para el logro de resultados.",
    "Manejar el alcance de las metas con optimismo y diplomacia.",
    "Manejar positivamente los conflictos",
    "Habilidad para impulsar procesos complejos y proyectos de envergadura.",
    "Ejercer el liderazgo con seguridad.",
    "Orientación en la obtención de resultados de calidad.",
    "Lograr resultados a través del equipo.",
    "Tener responsabilidad, decisión y ambición positiva.",
    "Resolver los problemas de forma innovadora.",
    "Habilidad para resolver conflictos entre el personal.",
    "Mantener los estándares de calidad, superando los obstáculos",
    "Influenciar al grupo con profesionalismo y firmeza.",
    "Generar confianza en las personas.",
    "Analizar los hechos ágilmente para la toma de decisiones.",
    "Actuar con sentido de urgencia en situación de presión.",
    "Obtener resultados de calidad con rapidez.",
    "Adaptación a los cambios y a los ambientes muy activos.",
    "Ser parte activa en la toma de decisiones.",
    "Tomar riesgos de forma realista.",
    "Habilidad para obtener resultados a través de la gente.",
    "Tener un buen manejo de los colaboradores y del equipo.",
    "Ser convincente.",
    "Ser persistente y competitivo",
    "Mantener altos niveles de exigencia.",
    "Facilidad de aproximación a las personas.",
    "Visión orientada hacia el futuro, de forma creativa.",
    "Mantener el interés en la calidad de forma activa e innovadora.",
    "Tener interés en la innovación de los protocolos y procedimientos formales.",
    "Adaptación al entorno, según el requerimiento de la ocasión.",
    "Mantener el control, proyectando amabilidad y receptividad."
  ],
  42: [
    "Manejar mucha información ágilmente.",
    "Analizar los hechos para la toma de decisión con sentido de urgencia.",
    "Convertir las ideas en algo práctico.",
    "Habilidad para comunicar y promover ideas de forma adecuada.",
    "Promover la cultura organizacional y los procesos de calidad.",
    "Capacidad de delegar y realizar seguimiento.",
    "Seguimiento de los plazos cuidando de los detalles delegados.",
    "Motivar a los demás en el seguimiento de los protocolos.",
    "Atención en los procesos y en los estándares de calidad a través de las personas.",
    "Habilidad para comunicar la planificación al equipo, indicando los pasos a seguir en proyectos complejos.",
    "Enfocarse en las relaciones y en la obtención de resultados de calidad.",
    "Ser impulsador de proyectos complejos y de envergadura.",
    "Responsabilidad y compromiso.",
    "Equilibrar adecuadamente la dedicación a las personas, las tareas y los detalles.",
    "Tener un ritmo de trabajo rápido, pero sin atropellar.",
    "Capacidad para tomar decisiones con sentido de urgencia.",
    "Mantener un buen nivel de análisis de la información disponible.",
    "Capacidad de relajar ambientes tensos, con buen manejo del humor y diplomacia.",
    "Mantener la atención en los estándares de calidad.",
    "Generar motivación en el equipo por el logro de resultados excelentes.",
    "Orientar a las personas adecuadamente.",
    "Alinear al equipo en las tareas claves de los proyectos y en el control de la calidad.",
    "Motivar al equipo a lograr las metas.",
    "Bajo presión ser una parte clave del equipo.",
    "Lograr buenos rendimientos del equipo sin afectar el clima laboral, en situaciones de tensión.",
    "Ser detallista en la planificación.",
    "Habilidad para comunicar los planes al equipo.",
    "Profesionalismo ante los retos y desafíos.",
    "Saber atender las necesidades de las personas.",
    "Habilidad en la negociación de conflictos.",
    "Ofrecer un clima laboral sensible a la calidad y el rendimiento.",
    "Crear un ambiente estimulante, competente, de integración y de logros.",
    "Capacidad de expresar sentimientos y sensibilidad cuando sea necesario en aras del logro de resultados e integración del equipo.",
    "Entusiasmo para competir y ganar.",
    "Estimular al equipo, a la hora de enfrentar los obstáculos.",
    "Ser consciente en la supervisión de los procesos de forma dinámica.",
    "Revisar la información requerida para actuar.",
    "Demostrar optimismo y confianza en el respeto de los protocolos y procedimientos.",
    "Ser auténtico.",
    "Alinear las emociones en función de mantener el enfoque en el rendimiento y en la calidad."
  ],
  43: [
    "Enfoque en las relaciones principalmente.",
    "Mantener una buena red de contactos y cuidar de la calidad.",
    "Inyectar entusiasmo y motivación al equipo.",
    "Ser amable y amistoso en el trato a las personas.",
    "Motivar al mejoramiento profesional de las personas.",
    "Comunicación asertiva.",
    "Ofrecer entrenamiento orientado al logro de los estándares de calidad.",
    "Entablar relaciones con los diferentes estilos de personas.",
    "Influenciar profesionalismo a las personas.",
    "Ser ejemplo de profesionalismo para el equipo.",
    "Generar entusiasmo y optimismo.",
    "Ser responsable, metódico, sistemático y persistente.",
    "Promover la creación de protocolos para el control eficiente de la calidad.",
    "Construir relaciones estables y sinceras.",
    "Disponibilidad para apoyar.",
    "Mantener en las personas el interés en la calidad, mediante una comunicación amigable y persuasiva.",
    "Estimular a los demás al abordaje de los procesos.",
    "Motivar de forma positiva el seguimiento de los protocolos, normas y procedimientos.",
    "Persistir en la aplicación de los protocolos establecidos.",
    "Demandar las explicaciones del porqué de los cambios.",
    "Explicaciones detalladas frente al cambio.",
    "Compromiso con el cumplimiento.",
    "Estimular a la innovación en las metodologías y procesos, en un clima favorable.",
    "Habilidad para entrenar y guiar con paciencia a los demás.",
    "Cuidar de los controles de calidad.",
    "Reconocer los logros de los demás.",
    "Habilidad para negociar y motivar a los demás.",
    "Lograr la colaboración del equipo mediante la comunicación y el análisis.",
    "Ser conciliador entre las personas en situaciones de conflicto.",
    "Enfoque en el ganar-ganar.",
    "Actitudes de generosidad y amabilidad.",
    "Profesionalismo y responsabilidad.",
    "Valorar el desarrollo profesional de las personas.",
    "Comunicar con asertividad y énfasis si el momento lo amerita.",
    "Motivación al crecimiento intelectual.",
    "Habilidad para hacer comprender a otros las complejidades de un proyecto.",
    "Comunicar y transmitir positivamente los mensajes."
  ],
  44: [
    "Enfoque en las relaciones.",
    "Mantener buenas redes de contacto y cuidar de la calidad.",
    "Cumplir con las responsabilidades, los plazos y la calidad.",
    "Comunicación asertiva ante el incumplimiento de los demás.",
    "Estimular el logro con profesionalismo.",
    "Seriedad y responsabilidad con las tareas.",
    "Inyectar entusiasmo y motivación al equipo.",
    "Ofrecer a los demás un buen trato con amabilidad buen sentido de humor.",
    "Ser una persona comunicativa, modesta y asertiva, cuando es necesario.",
    "Entrenar a los demás en el logro de los estándares de calidad.",
    "Llevar buenas relaciones con los diferentes etilos de personas.",
    "Conseguir los mejores resultados, cumpliendo con los protocolos.",
    "Utilizar la asertividad en la comunicación y en la persuasión.",
    "Enfoque en la calidad de los procesos.",
    "Estimular a los otros al abordaje de los procesos, normas y procedimientos.",
    "Motivar a los demás a innovar en las metodologías.",
    "Aplicar la innovación en los procesos para abordar los problemas.",
    "Ofrecer coaching en áreas técnicas.",
    "Reconocer los logros públicamente.",
    "Habilidades para negociar y motivar a las personas.",
    "Lograr la colaboración del equipo con habilidad comunicacional y análisis,",
    "Ser de actitud conciliadora y no confrontadora ante conflictos entre las personas.",
    "Orientación al ganar-ganar.",
    "Demostrar ser una persona central y clave en el cargo."
  ],
  45: [
    "Inyección de motivación y estabilidad en el equipo.",
    "Enfoque principal en las relaciones.",
    "Buena integración con los demás.",
    "Interés en el cuidado de la calidad, sin perder la importancia de las necesidades de los demás.",
    "Jugar en equipo.",
    "Ser abierto y receptivo con las personas.",
    "Ceder la atención del protagonismo a otros cuando sea necesario.",
    "Proyectar paciencia y confianza hacia los demás.",
    "Mantener la buena disposición con los demás.",
    "Conciliador frente a los conflictos.",
    "Apoyar al equipo, en el esfuerzo de alcanzar las metas.",
    "Mostrar consideración hacia los demás.",
    "Sentido de urgencia en momentos de presión",
    "Motivar a los demás al mejoramiento profesional.",
    "Comunicación asertiva cuando sea necesario.",
    "Entrenar al grupo en el logro de los estándares de calidad.",
    "Afrontar las responsabilidades de forma metódica, sistemática y persistente.",
    "Promover la creación de protocolos, para el control eficiente de la calidad.",
    "Habilidad para comunicar con persuasión.",
    "Mantener una comunicación amigable y enfocada en la calidad.",
    "Ser detallista y perfeccionista en la rutina.",
    "Estimular a los demás de forma positiva, en el abordaje de los procesos.",
    "Estimular el seguimiento de protocolos, normas y los procedimientos.",
    "Demandar detalles en las explicaciones cuando se presentan cambios.",
    "Explicaciones detalladas para asumir los cambios.",
    "Compromiso con las responsabilidades.",
    "Estimular a los demás a innovar en las metodologías y procesos.",
    "Habilidad para entrenar técnicamente.",
    "Habilidad para negociar y motivar a los demás.",
    "Lograr la colaboración del equipo con habilidades comunicacionales y de análisis.",
    "Conciliador ante conflictos entre las personas.",
    "Enfoque en el ganar-ganar.",
    "Ser de actitud generosa y amable.",
    "Preparación constante.",
    "Utilizar una comunicación asertiva y enfática cuando sea necesario.",
    "Habilidad de hacer comprender a otros, las complejidades de un proyecto.",
    "Comunicación positiva para transmitir el mensaje."
  ],
  46: [
    "Capacidad para comunicar al delegar las responsabilidades.",
    "Ser entusiasta en la ejecución de las responsabilidades.",
    "Ser un experto en un área de conocimiento con actitud amigable.",
    "Habilidades para relacionarse.",
    "Sentido de oportunidad para el buen humor.",
    "Asumir los procedimientos de calidad y los protocolos.",
    "Capacidad para ayudar a calmar los ánimos en momentos de tensión.",
    "Dominar el área de conocimiento técnico y profesional",
    "Ser amplio y receptivo en las relaciones con los demás.",
    "Capacidad para motivar a las personas.",
    "Demostrar paciencia con las personas.",
    "Cuidar del control de calidad y el respeto a los procedimientos.",
    "Generar vínculos sinceros y estables.",
    "Saber trabajar en equipo.",
    "Actitud amable y amistosa y de buen trato con las personas.",
    "Transmitir entusiasmo a la gente para llevar adelante los procesos.",
    "Cumplir con las normas para alcanzar los estándares de calidad.",
    "Brindar confianza a las habilidades de los demás.",
    "Mantener el equilibrio en los ritmos de trabajo para lograr los resultados.",
    "Dedicar tiempo al análisis de la información y los hechos para actuar.",
    "Sentido de los plazos y sus tiempos.",
    "Proyección en la calidad y en los resultados obtenidos.",
    "Dar críticas constructivas.",
    "Habilidad para motivar al equipo.",
    "Saber trabajar en equipo.",
    "Habilidad para canalizar al equipo en la misión de la empresa.",
    "Buena administración de las responsabilidades y las tareas,",
    "Sembrar optimismo y motivación dentro del equipo con sentido de realidad.",
    "Habilidad para entrenar a los demás en un área de especialidad.",
    "Mantenerse actualizado."
  ],
  47: [
    "Mantener el enfoque en las personas.",
    "Ser empático con los demás.",
    "Ser persistente en los proyectos.",
    "Mantener posturas firmes en los planteamientos y propuestas, de forma amigable.",
    "Capacidad para alinear a las personas con actitud positiva e integradora.",
    "Generar ambientes de cooperación y compañerismo de forma activa.",
    "Ser buen oyente y comprensivo con las personas sin perder el foco en los resultados.",
    "Habilidad para negociar los conflictos entre las personas.",
    "Capacidad de comprensión de los distintos puntos de vista involucrados en un conflicto.",
    "Aportar al equipo capacidad para solucionar los problemas con creatividad.",
    "Habilidad para incluir a la gente para crear soluciones a los problemas.",
    "Apoyar al equipo y aportar innovación a los proyectos.",
    "Jugar bien en equipo.",
    "Tener la sensibilidad para atender las necesidades de los demás.",
    "Atender al equipo, sin perder de vista los resultados del proyecto.",
    "Estar orientado al logro de los resultados.",
    "Tener el adecuado sentido de urgencia en los procesos.",
    "Habilidad para tomar las decisiones para la solución de problemas comunes.",
    "Valorar los niveles de lealtad del equipo.",
    "Capacidad para influenciar a los demás con una buena expresión y comunicación.",
    "Disposición a ayudar y a orientar como líder del proyecto.",
    "Tener paciencia, dedicación y disponibilidad.",
    "Tener buena actitud para innovar.",
    "Respeto por los métodos establecidos.",
    "Capacidad para desarrollar complejos protocolos y hacerles seguimiento.",
    "Identificación con el equipo",
    "Demostrar energía para trabajar duro, por una causa o por una meta.",
    "Enfoque en la solución de los problemas, cuidando a las personas involucradas.",
    "Capacidad para calmar a las personas cuando pierden el control emocional.",
    "Crear un ambiente estable, sincero, estimulante, creativo y positivo, para trabajar.",
    "Mantener el ritmo del equipo, cuidando los resultados.",
    "Capacidad de adaptación al entorno para el logro de los objetivos.",
    "Actitud calmada en condiciones normales.",
    "De ritmo rápido y enérgico cuando la situación lo amerite.",
    "Ser colaborador con los demás en situaciones de tensión, para avanzar en los resultados.",
    "Ser una persona generosa y bondadosa, de una manera activa.",
    "Habilidad para presionar a los demás con gentileza, para la obtención de resultados.",
    "Asumir riesgos sin afectar la estabilidad.",
    "Actualizar sistemas y métodos establecidos.",
    "Demostrar entusiasmo ante los retos y los desafío en la solución de problemas."
  ],
  48: [
    "Ser soporte de los esfuerzos del equipo, con sentido de innovación.",
    "Orientación hacia las personas, al mantenimiento del ritmo y la creatividad.",
    "Habilidad para la planificación y creación de sistemas para los proyectos.",
    "Mantener la visión en los resultados, sosteniendo un buen ambiente de trabajo.",
    "Habilidad para presionar con gentileza, para la obtención de los resultados.",
    "Asumir riesgos sin afectar la estabilidad.",
    "Actualizar los sistemas y en los métodos establecidos.",
    "Tener un buen sentido de equipo.",
    "Entusiasmo para enfrentar los retos y los desafíos en la solución de problemas.",
    "Vocación de servicio de forma activa y dinámica.",
    "Orientar a las personas en el logro de los objetivos.",
    "Lograr resultados únicos, orientado en la gente.",
    "Habilidad para relacionarse e integrar al equipo.",
    "Mantener una actitud equilibrada ante el equipo de trabajo.",
    "Estabilidad y dinamismo.",
    "Comunicación sincera.",
    "Crear un ambiente estable, sincero, estimulante, creativo y positivo.",
    "Ser colaborador y buen integrante del equipo.",
    "Trabajar para lograr la misión de la empresa, con lealtad.",
    "Habilidad para conciliar en los conflictos de forma activa.",
    "Visión global y de detalles en los conflictos.",
    "Capacidad para intermediar y ayudar a conciliar las partes.",
    "Ser paciente.",
    "Estimular a los demás para mantener el ritmo y el enfoque en las tareas.",
    "Respeto por las normas y los procedimientos de la empresa.",
    "Actitud para evitar los conflictos.",
    "Tener buen trato con la gente.",
    "Liderazgo positivo.",
    "Actitud conciliadora e imparcial en ambientes de tensión.",
    "Trabajar duro y con energía, por una causa o por una meta.",
    "Actitud colaboradora.",
    "Tener enfoque en la solución de problemas, cuidando a las personas involucradas.",
    "Capacidad de calmar a aquellos que están fuera de control emocional.",
    "Ser una persona generosa y bondadosa de una manera activa."
  ],
  49: [
    "Habilidad de negociar los conflictos entre las personas.",
    "Procurar resultados de ganar-ganar.",
    "Ofrecer apoyo tanto en el plano laboral como personal.",
    "Utilizar el sentido de la persuasión con calidez humana y sinceridad.",
    "Capacidad de comprensión y motivación para la obtención de los resultados.",
    "Aportar al equipo creatividad, optimismo y estabilidad.",
    "Integración al equipo.",
    "Facilidad para relacionarse y llevarse bien con las personas.",
    "Construir redes con personas claves, con un enfoque principalmente en las relaciones.",
    "Demostrar interés por las necesidades de los demás.",
    "Ser paciente.",
    "Saber escuchar a las personas.",
    "Habilidad para expresar confianza a los demás.",
    "Actitud colaboradora y generosa.",
    "Tener buena disposición con los demás.",
    "Apoyar al equipo en el esfuerzo por alcanzar las metas.",
    "Habilidad para presionar a los demás de forma amable, para la obtención de resultados.",
    "Orientación hacia el logro de resultados, en un fin común.",
    "Asumir riesgos sin afectar la estabilidad.",
    "Actualizar los sistemas y métodos establecidos.",
    "Aportar estímulo y entusiasmo al ambiente laboral.",
    "Liderazgo orientado al servicio de la gente con miras a lograr resultados.",
    "Liderazgo activo y pionero.",
    "Ser ecuánime y conciliador en ambientes de tensión.",
    "Mostrar sentido de urgencia cuando la situación lo amerite.",
    "Habilidad para construir relaciones positivas de forma activa.",
    "Brindar coaching personal si es necesario."
  ],
  50: [
    "Disponibilidad para colaborar con los demás de forma activa.",
    "Orientación hacia las relaciones.",
    "Habilidad de integración y buenas relaciones con la gente.",
    "Capacidad de influir en las personas para la obtención de los resultados.",
    "Integrarse al equipo.",
    "Enfoque en los resultados en cooperación con los demás en un fin común.",
    "Asumir los riesgos sin afectar la estabilidad.",
    "Actualizar los sistemas y métodos establecidos.",
    "Reconocer la importancia de los demás.",
    "Habilidad para comunicar.",
    "Habilidades de coach.",
    "Interés por lograr la participación de todos los involucrados.",
    "Mantener un buen clima laboral y evitar los conflictos.",
    "Administras los tiempos de entrega de los proyectos.",
    "Liderar orientado al servicio de la gente, con miras a lograr resultados únicos.",
    "Asumir con dinamismo un liderazgo activo y pionero centrado en las personas.",
    "Actitud conciliadora en ambientes de tensión.",
    "Enfocar al equipo hacia el progreso con voluntad y colaboración.",
    "Ser considerado, paciente, de fácil trato y buen humor.",
    "Consciencia en los ritmos para la toma de decisiones.",
    "Sentido de urgencia cuando la situación lo amerite.",
    "Negociación con positivismo en momentos de conflicto.",
    "Motivación en el equipo para el logro de los resultados.",
    "Habilidad para comunicar instrucciones con firmeza y amabilidad.",
    "Interés en mantener los vínculos y las relaciones.",
    "Habilidad para construir vínculos positivos con las personas, activamente.",
    "Escuchar a la gente con actitud de apertura.",
    "Disponibilidad para orientar y ofrecer consejo a las personas.",
    "Manejar bien tanto a grupos grandes como en grupos pequeños.",
    "Capacidad para comunicar.",
    "Tomar en consideración a la persona que requiera atención.",
    "Habilidades para relacionarse e integrar al equipo.",
    "Mantener una actitud equilibrada ante el equipo.",
    "Demostrar estabilidad.",
    "Ser generoso y amigable con los demás.",
    "Motivar al equipo de forma positiva para el logro de resultados.",
    "Permitir la iniciativa en las ideas con libertad de expresión.",
    "Capacidad de transmitir las ideas sugiriéndolas.",
    "Estimular la sinceridad dentro del equipo",
    "Perseverancia durante toda la ejecución del proyecto.",
    "Estimular las ideas creativas dentro del desarrollo del proyecto.",
    "Cuidar por el respeto a los protocolos probados.",
    "Ser agente clave de colaboración dentro de la organización."
  ],
  51: [
    "Enfoque hacia las relaciones.",
    "Integración con los demás y mantener el buen trato con la gente.",
    "Consideración a las necesidades de los demás.",
    "Persuadir a través de la calidez humana.",
    "Dar importancia a los procesos y a la calidad, basado en la sinceridad y en la comprensión.",
    "Comprensión de los detalles para asumir los cambios.",
    "Ser optimista y confiar en las personas de forma realista.",
    "Estimular al equipo a lograr buenos niveles de calidad.",
    "Ser paciente y conciliador.",
    "Aportar al equipo alegría, optimismo y estabilidad.",
    "Capacidad para trabajar en equipo.",
    "Ser afable, atrayendo a las personas para apoyarlas en lo que necesiten.",
    "Probar y clarificar toda la información posible antes de proceder.",
    "Mantener buenos estándares de calidad.",
    "Seguir las vías de los protocolos establecidos.",
    "Ser consistente, consciente y considerado.",
    "Ambiente democrático e instrucciones claras y detalladas.",
    "Mostrar actitudes de receptividad y apertura.",
    "Compartir el protagonismo y el control con los demás.",
    "Proyectar confianza y sinceridad.",
    "Pensar con detalle, análisis y método.",
    "Resolver los problemas con un enfoque desde todos los ángulos.",
    "Evitar las confrontaciones con autocontrol.",
    "Ser servicial, amigable y alegre.",
    "Saber escuchar con actitud empática y compasiva.",
    "Colaborar con generosidad.",
    "Tener buena disposición hacia los demás.",
    "Ser sistemático y rápido en lo rutinario.",
    "Ser analítico y cauteloso en las decisiones importantes.",
    "Reflexión y análisis para la toma de decisión.",
    "Apoyo y colaboración con el equipo en el esfuerzo de alcanzar las metas.",
    "Aportar estímulo y entusiasmo al ambiente laboral.",
    "Cumplir con los plazos.",
    "Habilidad para construir relaciones positivas.",
    "Disponibilidad para ofrecer asesoramientos personales en el área técnica.",
    "Tomar decisiones de forma consciente.",
    "Interés en los métodos, los procedimientos y los estándares.",
    "Atender los patrones y las relaciones."
  ],
  52: [
    "Trabajar en equipo.",
    "Ser paciente y con facilidad para tratar a los demás.",
    "Habilidad para la comunicación verbal.",
    "Enfoque en las tareas y responsabilidades.",
    "Actitud optimista y amistosa.",
    "Seguir lineamientos del líder.",
    "Entusiasmo para afrontar la tarea metódicamente.",
    "Ser conciliador para evitar el conflicto.",
    "Seriedad y profesionalismo en los retos y dificultades.",
    "Seguir los protocolos y los procedimientos, con actitud positiva.",
    "Estabilidad.",
    "Planificación y organización en situaciones de cambio.",
    "Análisis de los hechos y la información, para tomar decisiones.",
    "Establecer rutinas y ritmos de trabajo.",
    "Seguir el patrón de forma consistente.",
    "Ser colaborador motivando a los demás.",
    "Habilidad para combinar ágilmente la paciencia, el razonamiento, la tolerancia y el entusiasmo.",
    "Influenciar a los demás de forma entusiasta.",
    "Capacidad para analizar, evaluar y reflexionar.",
    "Generar en ambiente de trabajo enfocado en el servicio, la amabilidad y el cumplimiento.",
    "Atención y cautela a los detalles.",
    "Capacidad para comunicar con lógica e investigación.",
    "Tener buen sentido del humor de forma oportuna.",
    "Enfoque en las relaciones, cuidando la calidad en el trabajo.",
    "Saber seguir las instrucciones y cumplir las normas.",
    "Habilidad para manejar a la gente, sin perder de vista el proyecto.",
    "Inspirar confianza con profesionalismo.",
    "Ser firme y amable en la comunicación.",
    "Orientar a otros para que aprendan habilidades técnicas y profesionales.",
    "Constancia en el desarrollo profesional para poder hacer bien el trabajo.",
    "Enfrentar las responsabilidades y retos de forma metódica y sistemática.",
    "Seguir los protocolos establecidos.",
    "Consciencia en las habilidades y talentos."
  ],
  53: [
    "Ser cauteloso, atento y detallista.",
    "Ser amigable y servicial, aportando jovialidad al ambiente de trabajo.",
    "Respetar los estándares de calidad en sus resultados.",
    "Lograr un buen nivel de adaptación y conocimiento de la cultura organizacional.",
    "Dar importancia a analizar, evaluar y reflexionar.",
    "Asumir las responsabilidades con compromiso.",
    "Enfoque es tareas y responsabilidades con motivación.",
    "Buena comunicación verbal, cuando hay algo necesario que decir.",
    "Asumir la solución de los problemas con protocolos probados.",
    "Mantener el seguimiento de los protocolos y el foco en las personas.",
    "Orientar al equipo en los métodos, sistemas y procedimientos establecidos.",
    "Mantener el clima positivo con buen humor, informalidad y respeto por las normas.",
    "Actitud amigable y diplomática.",
    "Orientar a los demás para que aprendan habilidades técnicas.",
    "Ser un coach en el equipo.",
    "Habilidad técnica y capacidad, para transmitir mensajes",
    "Mantener un nivel de pensamiento crítico y de capacidad de análisis.",
    "Habilidad para relajar el ambiente laboral, con una cuota proporcionada de buen humor.",
    "Reflexión y análisis para tomar acciones y decisiones.",
    "Trabajar respetando los plazos de entrega.",
    "Mejoramiento profesional constante.",
    "Ser especialista en un área profesional.",
    "Ser paciente con las personas.",
    "Ser generoso, amable, diplomático y amigable.",
    "Habilidad para debatir amablemente, con argumentos sólidos, los puntos de vista.",
    "Progresar en los niveles técnico-profesional.",
    "Aprendizaje constante.",
    "Exigencia con el control de la calidad.",
    "Enfrentar las responsabilidades y retos de forma metódica.",
    "Hacer seguimiento sistemático y persistente, de los protocolos establecidos.",
    "Demostrar con los resultados, el nivel profesional.",
    "Consciencia en las habilidades y talentos.",
    "Persuadir a los demás, demostrando nivel profesional y optimismo realista."
  ],
  54: [
    "Alcanzar altos estándares de calidad en los resultados.",
    "Lograr altos niveles de adaptación y conocimiento de la cultura organizacional.",
    "Comunicación verbal cuando hay algo necesario que decir.",
    "Comunicar el enfoque en las tareas y responsabilidades.",
    "Enfoque en tareas y responsabilidades con motivación.",
    "Asumir las responsabilidades con compromiso.",
    "Capacidad para analizar, evaluar y reflexionar.",
    "Ser amigable y servicial, aportando jovialidad al ambiente de trabajo.",
    "Ser cauteloso, atento y detallista.",
    "Asumir la solución de los problemas, siguiendo los protocolos y el foco en las personas.",
    "Orientar al equipo en los métodos, sistemas y procedimientos establecidos.",
    "Mantener un clima laboral positivo, con buen humor, pudiendo ser flexible e informal.",
    "Confiar en las habilidades de los demás.",
    "Habilidad para relacionarse con los demás y motivarlos.",
    "Mantener buena relación dentro del grupo.",
    "Capacidad de relajar el ambiente laboral, con buen humor de forma oportuna.",
    "Reflexión y análisis antes de actuar y tomar decisión.",
    "Trabajar con un buen nivel de conciencia en los plazos de entrega.",
    "Ser experto en el área profesional.",
    "Ser paciente, generoso, amable, diplomático y amigable con las personas.",
    "Habilidad para debatir amablemente, con argumentos sólidos, los puntos de vista.",
    "Utilizar un buen nivel de pensamiento crítico y de capacidad de análisis.",
    "Ser un coach en el equipo.",
    "Habilidad para transmitir el mensaje con técnica y paciencia.",
    "Orientar a los demás para que aprendan habilidades técnicas.",
    "Constante mejoramiento profesional.",
    "Enfrentar las responsabilidades y retos de forma metódica.",
    "Ser sistemático y persistente, en el seguimiento de los protocolos.",
    "Demostrar con resultados el nivel profesional.",
    "Consciencia en las habilidades y talentos.",
    "Demostrar con persuasión el nivel profesional y optimismo realista que se requiere.",
    "Expresar, amablemente, las observaciones de rendimiento y estándares de calidad."
  ],
  55: [
    "Responsabilidad ante los retos de forma metódica y sistemática.",
    "Seguimiento constante de los protocolos establecidos para lograr resultados.",
    "Influenciar a los demás con sentido de responsabilidad.",
    "Responsabilidad con las tareas y proyectos.",
    "Actitud curiosa para buscar soluciones a los problemas.",
    "Procurar encontrar la mejor solución.",
    "Disponibilidad para colaborar con los otros activamente.",
    "Consciencia de las habilidades y talentos.",
    "Capacidad de trabajo para asumir las tareas e integrarse al equipo.",
    "Ser un líder en acción para asumir las tareas.",
    "Ofrecer entrenamiento para capacitar competencias.",
    "Ejercer con autonomía y competencia.",
    "Analizar la información y los hechos disponibles para tomar posición.",
    "Habilidad para comunicar con consideración y firmeza.",
    "Seguimiento y compromiso con los procedimientos y resultados de forma consistente.",
    "Anticipar las oportunidades en las asignaciones retadoras.",
    "Alto rendimiento, cuidando de la calidad.",
    "Ser recursivo frente a los problemas.",
    "Poseer un buen nivel de pensamiento crítico y creativo.",
    "Capacidad para ver el detalle y lo global de un problema.",
    "Saber captar las fallas y las desviaciones con agilidad.",
    "Dar valor a los métodos y sistemas, manteniendo un nivel adecuado de flexibilidad.",
    "Transmitir confianza a la hora de dar resultados y contar con la colaboración.",
    "Estimular al equipo, enfocando las situaciones desde una visión justa.",
    "Enfoque en las ganancias para todos.",
    "Logra alinear las metas personales con las de la organización.",
    "Comportamiento colaborativo.",
    "Garantizar que el trabajo se haga bien.",
    "Comunicar con firmeza y actitud considerada, basado en el buen juicio.",
    "Mantener la ética del trabajo dentro del equipo."
  ],
  56: [
    "Enfrentar las responsabilidades y retos, de forma metódica y sistemática.",
    "Persistencia en el seguimiento de los protocolos establecidos.",
    "Sostener un ritmo estable para la obtención de resultados de calidad.",
    "Aplicar nuevas ideas, cuidando del control de calidad.",
    "Consistencia en las habilidades y talentos.",
    "Mantener un enfoque realista en los protocolos y sistemas.",
    "Capacidad creativa en la solución de los problemas y en los resultados.",
    "Tener un sentido equilibrado de la competencia.",
    "Enfoque en el detalle cuidando de los aspectos del proyecto.",
    "Actitud paciente para mantener al equipo activo y reflexivo.",
    "Cuidado de los estándares de calidad en los resultados.",
    "Capacidad de adaptación al ritmo del entorno, con enfoque en los resultados.",
    "Privacidad para pensar en los proyectos y soluciones.",
    "Enfrentar retos y desafíos, con objetividad e innovación.",
    "Trabajar con orden, limpieza y organización.",
    "Asumir los cambios de forma programada.",
    "Valor ante las adversidades.",
    "Ser meticuloso en el análisis de la información para la obtención de resultados.",
    "Manejar los detalles necesarios en cuanto al nivel de responsabilidad.",
    "Libertad creativa en ciertas circunstancias.",
    "Comunicación con tacto y diplomacia.",
    "Escuchar con empatía, consideración y análisis.",
    "Equilibrio en el enfoque de las relaciones y los resultados.",
    "Motivación al logro de resultados, de forma equilibrada.",
    "Jugar bien en equipo, con apoyo y colaboración.",
    "Velar porque los procesos y protocolos se cumplan.",
    "Compartir las ideas con los demás con actitud generosa.",
    "Cuidar de la seguridad en los proyectos.",
    "Prioridad al logro de los objetivos, cuidando la calidad y la seguridad en la ejecución.",
    "Invertir tiempo en el análisis de la información y evaluación de los hechos.",
    "Garantizar la calidad, previa evaluación para la toma de decisión",
    "Motivar el cumplimiento de los proyectos.",
    "Firmeza en el cumplimiento de estándares de calidad y el logro de resultados.",
    "Compromiso con los tiempos de entrega y cumplir con los plazos.",
    "Actitud receptiva y entusiasta con los demás.",
    "Exigir cumplir con las normas y procedimientos."
  ],
  57: [
    "Enfrentar las responsabilidades y retos, de forma metódica y sistemática.",
    "Seguimiento de los protocolos para la obtención de los resultados.",
    "Consistencia en las habilidades y talentos.",
    "Enfoque realista de los protocolos, sistemas y procedimientos.",
    "Orientación realista en las normas y metodologías de la organización.",
    "Mantener el enfoque en el detalle y cuidado de todos los aspectos del proyecto.",
    "Cuidar por el mantenimiento de los estándares de calidad.",
    "Capacidad de adaptación al ritmo del entorno, para enfocar los resultados.",
    "Exigir el cumplimiento de las normas y procedimientos.",
    "Mantener altos estándares de calidad.",
    "Estimular a los demás a prestar atención a los datos y detalles",
    "Promover la atención de los demás en los procesos de investigación y análisis de toda la información.",
    "Estimular el uso de la lógica y la razón.",
    "Ser diplomático y bien educado.",
    "Motivar a las personas a cumplir con los proyectos.",
    "Resguardar los estándares de calidad para el logro de los resultados.",
    "Generar ambientes que valoren la importancia de la calidad del trabajo.",
    "Hacer el trabajo bien hecho, desde el inicio.",
    "Seguir los procedimientos establecidos, para el logro del trabajo efectivo.",
    "Ser meticuloso y detallista con la información.",
    "Buscar la información inherente a su nivel de responsabilidad.",
    "Emplear la creatividad cumpliendo con los protocolos.",
    "Comunicar con diplomacia y cierto grado de firmeza.",
    "Escuchar con actitud empática, considerada y con pensamiento analítico.",
    "Mantener el equilibrio entre la naturaleza humanista y la motivación al logro de resultados.",
    "Trabajar bien con el equipo, apoyando y colaborando.",
    "Estabilidad y tiempo para asumir los cambios.",
    "Ser profesional en el área.",
    "Mantener los estándares de calidad.",
    "Enfrentar los retos y desafíos con objetividad e innovación.",
    "Ser ordenado, limpio y organizado para trabajar de forma activa."
  ],
  58: [
    "Enfrentar las responsabilidades y retos, de forma metódica y sistemática.",
    "Persistencia en el seguimiento de los protocolos establecidos.",
    "Consciencia en las habilidades y talentos.",
    "Enfoque realista en los protocolos y sistemas.",
    "Orientación hacia los procedimientos, normas y metodologías de la organización.",
    "Enfoque en el proyecto con cuidado y detalle de todos los aspectos.",
    "Mantener de los estándares de calidad.",
    "Capacidad de adaptación al ritmo del entorno, con enfoque en los resultados.",
    "Ser ordenado, limpio y organizado para trabajar.",
    "Estabilidad y tiempo para asumir los cambios.",
    "Manejar la información con meticulosidad y detalle.",
    "Comunicar con tacto y diplomacia.",
    "Saber escuchar con actitud empática y pensamiento analítico.",
    "Equilibrio entre la naturaleza humanista y la motivación al logro de resultados.",
    "Jugar bien en equipo con apoyo y colaboración.",
    "Ser un experto en el área.",
    "Velar por la atención a que los procesos y protocolos se cumplan.",
    "Compartir las ideas con los demás.",
    "Ser una persona generosa.",
    "Contar con la seguridad en los proyectos.",
    "Prioridad en el logro de objetivos, en la calidad y seguridad en la ejecución.",
    "Invertir tiempo en analizar información y evaluar los hechos.",
    "Capacidad de análisis en pro de garantizar la calidad.",
    "Exigencia en los estándares de calidad, en el logro de los resultados.",
    "Estar consciente de los tiempos de entrega.",
    "Cumplir con las normas y los procedimientos de forma activa."
  ],
  59: [
    "Ser realizador con ideas creativas.",
    "Motivación al cambio.",
    "Lograr resultados de calidad, con sentido de urgencia.",
    "Pensamiento analítico frente a las decisiones.",
    "Control emocional.",
    "Procurar resultados y soluciones de calidad.",
    "Ser frío y controlado en situaciones de presión.",
    "Mantener un clima de entusiasmo en situaciones favorables.",
    "Sentido de urgencia en la toma de decisiones rutinarias.",
    "Cautela en la toma de decisiones difíciles.",
    "Habilidad para emitir comentarios inesperados para relajar el ambiente si fuese necesario.",
    "Enfoque central es en el negocio, la productividad, en el rendimiento y las soluciones.",
    "Tener carácter firme y directo, en la comunicación con las personas.",
    "Ser muy recursivo.",
    "Tener un alto nivel de pensamiento analítico, crítico y visión de futuro.",
    "Ser creativo, innovador y explorador.",
    "Asumir con eficiencia un proyecto de principio a fin.",
    "Aportar ideas novedosas de forma meticulosa.",
    "Mantener altos estándares de calidad.",
    "Participar en muchos asuntos a la vez, cuidando de cumplir con los plazos.",
    "Capacidad para integrar rápidamente teorías e información diversa, para solucionar problemas.",
    "Estimular a la gente para trabajar en equipo, en pro de la meta.",
    "Demostrar capacidad para enfrentar los cambios y los retos.",
    "Participar en proyectos especializados y formar equipo con gente experta.",
    "Debatir las ideas para encontrar la mejor solución a los problemas técnicos.",
    "Orientación hacia lo importante, a la hora de analizar sus decisiones.",
    "Trabajar de forma consistente y comprometida con el logro de resultados de calidad.",
    "Calcular los riesgos de forma objetiva.",
    "Capacidad creativa para encontrar ideas pioneras.",
    "Ofrecer aportes favorables a los procesos y los protocolos establecidos.",
    "Revisar mucha información para la solución de los problemas.",
    "Motivación al cambio, impulsando y garantizando el éxito del negocio.",
    "Estimular al equipo el ritmo adecuado a la hora de ponerse en acción.",
    "Asumir proyectos que se realicen meticulosamente.",
    "Optimismo para el arranque en los proyectos.",
    "Claridad en las metas de desempeño.",
    "Habilidad para comunicar los pasos del proyecto y los planes a seguir."
  ],
  60: [
    "Enfoque en la obtención de resultados y en la gente.",
    "Tener tacto y diplomacia a la hora de dirigir a la gente.",
    "Comunicación con las personas de forma positiva y directa.",
    "Ofrecer críticas constructivas.",
    "Orientación hacia las soluciones con claridad y sustento.",
    "Utilizar el buen sentido de humor con diplomacia.",
    "Asumir con eficiencia un proyecto de principio a fin.",
    "Aportar ideas novedosas.",
    "Mantener los estándares de calidad con meticulosidad.",
    "Participar en muchos asuntos a la vez cumpliendo con los plazos.",
    "Analizar los hechos en situaciones de conflicto.",
    "Capacidad para comprender el conflicto y negociar entre las partes, buscando que todos ganen.",
    "Colocar el foco en los logros de las metas con calidad.",
    "Ofrecer estímulo para trabajar en equipo en pro de la meta común.",
    "Participar en proyectos especializados convocando gente experta.",
    "Evaluar y analizar situaciones complejas, sin perder el sentido de urgencia",
    "Mantener la agenda y la planificación con una cierta flexibilidad.",
    "Impulsar cambios y novedades.",
    "Respetar las normas, los procedimientos y los estándares.",
    "Asumir proyectos y metas complejas.",
    "Crear para el equipo, un ambiente de retos y de entusiasmo.",
    "Equilibrio en la toma de decisiones, tomando en cuenta los hechos y las necesidades de las personas.",
    "Asumir riesgos con carácter.",
    "Cumplir con la obtención de resultados de calidad.",
    "Tomar en cuenta a la gente y al equipo.",
    "Habilidad para comunicar los pasos del proyecto y los planes a seguir.",
    "Ser una persona analítica y creativa.",
    "Calcular los riesgos para actuar.",
    "Habilidad para comunicar con asertividad."
  ],
  61: [
    "Equilibrar la atención, entre la gente, los procesos y la obtención de resultados.",
    "Demostrar un buen nivel técnico en el área de conocimiento.",
    "Capacidad para conectar con la gente.",
    "Ejercer influencia en los demás en el área profesional.",
    "Ser exigente y considerado al mismo tiempo.",
    "Negociar conflictos entre las personas y equipos, buscando resultados de ganar-ganar.",
    "Equilibrio en el manejo de las relaciones personales.",
    "Establecer balance entre los protocolos, procedimientos y las relaciones personales.",
    "Respetar los plazos de cumplimiento.",
    "Establecer relaciones con actitud directa, positiva y diplomática.",
    "Aportar motivación al cumplimiento de procedimientos y obtención de resultados.",
    "Aportar a los proyectos la energía suficiente para llevarlos hasta el final con éxito.",
    "Una persona extrovertida y sociable y al mismo tiempo analítica y reflexiva.",
    "Capacidad para comunicar las normas, procedimientos y planes de acción.",
    "Estimular a los demás a logra resultados exitosos.",
    "Transmitir a los demás los criterios de nivel profesional.",
    "Ser de trato enérgico, amigable y sociable.",
    "Enfoque en la calidad, en los resultados y en la gente, para crear un clima laboral retador.",
    "Crear un ambiente de trabajo eficiente y amigable para el equipo.",
    "Seguimiento cercano de las labores delegadas a los otros.",
    "Tomar en cuenta a los demás, los hechos y la información, para tomar decisiones.",
    "Motivación frente a los retos y los desafíos profesionales.",
    "Tomar la iniciativa, para enfrentar tareas complejas y seguir los estándares de calidad.",
    "Comunicar con detalle y amabilidad el plan de trabajo.",
    "Actitud positiva ante las personas.",
    "Estimular a los demás, para que sigan las normas y los procesos establecidos.",
    "Estimular al equipo a resolver el conflicto.",
    "Capacidad de motivación para el mantenimiento del nivel de desempeño en alto.",
    "Calcular los riesgos con precisión y exactitud.",
    "Habilidad para lograr resultados a través de la gente.",
    "Mantener el seguimiento al cumplimiento de los estándares de calidad.",
    "Mostrar un optimismo realista, a la hora de asumir riesgos o enfrentar retos.",
    "Moderado sentido de urgencia.",
    "Capacidad de análisis frente a decisiones compleja.",
    "Ser un agente activo en la actualización de los protocolos y procedimientos.",
    "Empujar al equipo con diplomacia y optimismo.",
    "Estimular al equipo a la acción y al cumplimiento de los procesos.",
    "Demostrar equilibrio entre la lógica, la razón, la emoción y la intuición.",
    "Cuidar el cumplimiento de los estándares de calidad.",
    "Dominio de los sentimientos positivamente y orientados al resultado."
  ],
  62: [
    "Hacer críticas constructivas.",
    "Dar seguimiento a las tareas y cuidar el control de la calidad y los detalles.",
    "Dirigir a la gente con tacto y diplomacia.",
    "Habilidad para comunicar de forma positiva los protocolos y procesos.",
    "Enfocar los detalles, en los procesos y en la calidad.",
    "Mantener al equipo entusiasmado en el logro de resultados de calidad.",
    "Orientación a las soluciones mediante un enfoque claro y sustentado.",
    "Capacidad para distender los ambientes tensos, con sentido del humor de forma oportuna.",
    "Ser un referente dentro del equipo, por el profesionalismo y trato jovial.",
    "Comunicación amable y asertiva.",
    "Promover ideas y soluciones técnicas.",
    "Cuida la calidad y las necesidades de las personas.",
    "Enfoque analítico para producir ideas funcionales y pioneras.",
    "Capacidad para delegar, dar instrucciones y ofrecer recomendaciones.",
    "Liderazgo positivo basado en el profesionalismo.",
    "Habilidades de comunicación con las personas.",
    "Transmitir información técnicas y detalles de los proyectos.",
    "Inyectar optimismo al equipo, creando un ambiente de apertura para las correcciones.",
    "Habilidad para corregir a los demás protegiendo la autoestima.",
    "Análisis de la información y necesidades de las personas para la toma de decisiones.",
    "Capacidad para aclarar las metas y los objetivos.",
    "Impulsar los resultados con calidad.",
    "Promover el uso adecuado de los procesos y protocolos.",
    "Habilidad para negociar los conflictos de la gente, con justicia.",
    "Actitud enérgica para impulsar cambios y novedades.",
    "Respetar las normas, los procedimientos y los estándares.",
    "Ritmo moderado, a la hora de tomar acción y decisiones.",
    "Tener pensamiento crítico y analítico, pero activo.",
    "Lidiar con muchos compromisos al mismo tiempo.",
    "Administración adecuada del tiempo.",
    "Aportar valor a la empresa con habilidades comunicacionales.",
    "Dar a conocer la cultura de la organización y los paso a paso de un proyecto.",
    "Tomar riesgos calculados."
  ],
  63: [
    "Explicar de forma positiva, las complejidades de un proyecto.",
    "Equilibrar los ritmos para lograr resultados y tomar decisiones.",
    "Trabajar a un ritmo equilibrado.",
    "Velar por la protección de la autoestima de los demás.",
    "Brindar retroalimentación adecuadamente.",
    "Proyectar profesionalismo en el área técnica.",
    "Disponibilidad para ofrecer asesoramiento.",
    "Desenvolvimiento jovial, con buenos modales, diplomacia y sentido de humor.",
    "Habilidad para relajar ambientes tensos con humor de forma oportuna.",
    "Capacidad para comunicar con persuasión.",
    "Valorar con profesionalismo a las personas.",
    "Aplicar el liderazgo para el detalle y la perfección en el trabajo.",
    "Influir en los demás para ser meticulosos en el cumplimiento de los protocolos.",
    "Crear un clima amigable y no generar controversias.",
    "Capacidad técnica para comunicarse con los demás.",
    "Comunicar la importancia clave de la calidad y los protocolos, para el éxito de un proyecto.",
    "Buena actitud frente al trabajo de equipo.",
    "Habilidad de comunicación tanto con pequeños grupos, como con grupos grandes.",
    "Facilidad de comunicación tanto con gente conocida como con extraños.",
    "Orientación hacia la calidad y a las personas.",
    "Mostrar buen sentido del humor con tacto y diplomacia.",
    "Atender situaciones difíciles con diplomacia.",
    "Ser analítico y reflexivo.",
    "Aportar confianza al equipo con optimismo y entusiasmo.",
    "Mantener la vista puesta en los plazos.",
    "Responder a tiempo, todos los compromisos.",
    "Asumir retos profesionales complejos.",
    "Motivar al equipo a integrarse en pro de la meta común.",
    "Alcanzar el éxito, junto al equipo, en los proyectos que emprenden.",
    "Aplicar análisis, lógica y pensamiento crítico, equilibrando el aspecto emocional.",
    "Habilidad de comunicación lógica, reflexiva y expresiva."
  ],
  64: [
    "Equilibrio en el ritmo de productividad.",
    "Lograr resultados, asumir cambios y tomar decisiones a un ritmo equilibrado.",
    "Equilibrar el ritmo entre la urgencia y la pausa.",
    "Enfocar con balance la calidad y las personas.",
    "Habilidad para llevarse bien con una diversidad de gente.",
    "Motivar activamente a las personas a cumplir con las normas.",
    "Entusiasmo para llevar adelante los procesos y los estándares de calidad.",
    "Aportar optimismo al equipo y crear conciencia, sobre los riesgos y los controles necesarios.",
    "Manejo del buen sentido del humor.",
    "Atender situaciones difíciles con diplomacia.",
    "Ser analítico y reflexivo.",
    "Crear un clima amigable y no generar controversias.",
    "Saber entablar las relaciones con diplomacia.",
    "Generar entusiasmo en los demás y jugar bien en equipo.",
    "Mantener altos estándares de calidad.",
    "Valorar los requerimientos de las personas.",
    "Motivar y lograr el enfoque en lo importante de un proyecto.",
    "Habilidad para vislumbrar las tareas claves para el éxito.",
    "Proyectar profesionalismo al equipo",
    "Habilidades de coach, guía y asesor en áreas especializadas.",
    "Ser una persona crítica, de los procesos y la calidad.",
    "Establecer vínculos con las personas a través de las asignaciones.",
    "Ser detallista y perfeccionista.",
    "Habilidad para persuadir con optimismo, diplomacia e inclinación a la calidad.",
    "Guiar al equipo al logro del éxito con excelencia.",
    "Seguimiento a los proyectos para que se desarrolle exitosamente.",
    "Evaluar a las personas con optimismo.",
    "Capacidad para evaluar situaciones con tendencias opuestas.",
    "Saber manejar de forma estratégica las tendencias opuestas entre el pesimismo y la crítica, y el optimista y actitud confiada.",
    "Dirigir al equipo con tacto comprendiendo sus necesidades.",
    "Habilidad para mantener un equilibrio entre la lógica, la razón y las emociones.",
    "Poseer pensamiento analítico y objetivo, cuando es necesario.",
    "No perder de vista las implicaciones emocionales."
  ],
  65: [
    "Respeto por los plazos.",
    "Aproximación a las personas con diplomacia y actitud positiva.",
    "Mantener el equilibrio en el ritmo de la productividad.",
    "Lograr resultados, asumir cambios y toma de decisiones a un ritmo equilibrado.",
    "Balance del ritmo ni mucha urgencia, ni mucha pausa.",
    "Equilibrio en el manejo de las relaciones personales.",
    "Establecer balances entre los protocolos, procedimientos y las relaciones personales.",
    "Aportar motivación en el cumplimiento de los procedimientos y el cuidado de los detalles.",
    "Capacidad para negociar los conflictos entre las personas, con una visión de ganar-ganar.",
    "Aportar al proyecto la energía suficiente para llevarlos hasta el final con éxito.",
    "Ser extrovertido, sociable, así como habilidad para el análisis y la reflexión.",
    "Competencias para comunicar las normas, los procedimientos y planes de acción.",
    "Influir en los demás con encanto personal y su buen nivel profesional.",
    "Proyectar entusiasmo y optimismo de forma sincera.",
    "Ser perfeccionista y detallista en las ejecuciones.",
    "Transmitir a los demás, la cultura de la organización.",
    "Saber cuando actuar con sentido de urgencia en la toma de decisiones.",
    "Saber cuando tornarse a un ritmo pausado, para evaluar toda la información disponible.",
    "Dirigir con tacto, comprendiendo las necesidades de los demás y del equipo.",
    "Atención y cautela al asumir riesgos.",
    "Asumir los cambios con mentalidad abierta.",
    "Generar ambientes favorables y de confianza.",
    "Habilidad para dirigir a los demás en sus tareas y en el cumplimiento de las normas.",
    "Capacidad para asumir tareas especializadas, siendo clave en la motivación del equipo.",
    "Tener conocimiento de toda la información disponible, para la toma de decisiones estratégica.",
    "Reconocimiento profesional a la calidad del trabajo de los demás.",
    "Ser comunicativo y articulado para transmitir los pasos de un plan de acción.",
    "Tener competencia para promover ideas.",
    "Generar influencia en un área de especialidad."
  ],
  66: [
    "Enfoque tanto en la calidad como en las personas.",
    "Habilidad para entablar relaciones con una diversidad de gente, sobre todo personas cercanas.",
    "Ser paciente con las personas.",
    "Profesionalismo en el área técnica con consciencia del control de calidad.",
    "Orientación hacia las tareas y responsabilidades del día a día.",
    "Tener posiciones críticas frente a los procesos y la calidad.",
    "Capacidad de comunicación con los demás.",
    "Habilidad para verbalizar los procesos, los sistemas y los pasos de un proyecto.",
    "Ser especialista en el área profesional.",
    "Ser agente de armonía en la rutina.",
    "Habilidad para asumir varios proyectos complejos al mismo tiempo.",
    "Mantener un ambiente estable e integrado.",
    "Cumplir con los compromisos.",
    "Habilidad para entrenar a las personas de forma positiva y empática.",
    "Entrenar en procesos, sistemas, métodos, calidad y aspectos técnicos.",
    "Dirigir comprendiendo las necesidades de los demás y del equipo.",
    "Confiar en las habilidades de los demás.",
    "Motivación a progresar en el conocimiento del área profesional.",
    "Analizar hechos e información para la toma de decisión.",
    "Considerar la intuición y a los demás como elementos claves en la toma de decisión.",
    "Mostrar sensibilidad hacia los demás con precisión y exactitud.",
    "Generar entusiasmo en las ideas técnicas.",
    "Consciencia del factor tiempo.",
    "Ser detallista.",
    "Mantener altas expectativas en cuanto a la calidad.",
    "Motivar a los demás, a que se alineen las altas expectativas de calidad.",
    "Adecuar el tiempo y la planificación para asumir los cambios.",
    "Orientación en la obtención de resultados de calidad.",
    "Ayudar al progreso y desarrollo profesional de los otros.",
    "Lograr buenos niveles de pericia dentro de la empresa."
  ],
  67: [
    "Impulsar el cambio calculando los riesgos.",
    "Mantener el cuidado por los procesos que funcionan adecuadamente.",
    "Mejorar los procesos.",
    "Enfoque en la acción y en la tarea.",
    "Cuidar por mantener el buen clima laboral, orientado a la realización.",
    "Fijar el enfoque principalmente es en los procesos.",
    "Obtener resultados rápidos en la calidad, de forma metódica y reflexiva.",
    "Usar la creatividad, el análisis y cálculo de riesgos, para resolver los problemas o retos.",
    "Seguir las normas y procedimientos.",
    "Capacidad para proponer novedosas maneras de afrontar los problemas o los proyectos.",
    "Profesionalismo en la tarea.",
    "Dedicación para conseguir los resultados rápidamente.",
    "Mantener el respeto por los protocolos establecidos y los estándares de calidad.",
    "Capacidad para debatir ideas, para crear respuestas de alta calidad.",
    "Habilidad para discutir ideas para la solución de los problemas, evitando los conflictos.",
    "Tener actitudes amables, diplomáticas y objetivas.",
    "Analizar la información disponible y los hechos para la toma de decisión.",
    "Analizar los problemas nuevos, manteniendo el sentido de urgencia.",
    "Capacidad para reflexionar cuando sea necesario y acelerar el ritmo de acuerdo a las circunstancias.",
    "Capacidad de análisis para la toma de decisiones, con enfoque y eficiencia.",
    "Mantener el centro de interés en el negocio y en la productividad.",
    "Mantener los estándares de calidad, tratando de cuidar el buen clima laboral.",
    "Prestar atención al orden y a la disciplina.",
    "Mantener el lugar de trabajo ordenado y limpio.",
    "Ofrecer ideas creativas, a la hora de proponer nuevos sistemas y protocolos.",
    "Innovar para hacer protocolos más eficientes, productivos o con mayor calidad.",
    "Adaptar la forma tradicional de trabajar y de cuidar la calidad.",
    "Mantener el cuidado de las reglas y las normas, con la flexibilidad necesaria.",
    "Visión en el futuro y en la forma en cómo establecer normas y procedimientos.",
    "Crear soluciones novedosas y eficientes a los problemas y a la producción.",
    "Tomar decisiones manteniendo un enfoque cuidadoso, analítico y preciso.",
    "Equilibrio y ritmo en la toma de decisiones con diligencia y análisis.",
    "Decisiones con sentido de urgencia en la rutina.",
    "Analizar y revisar opciones frente a decisiones importantes o temas nuevos."
  ],
  68: [
    "Ser práctico y objetivo.",
    "Aplicar protocolos en la solución de problemas nuevos.",
    "Cuidar de la calidad y de los resultados, manteniendo un buen clima laboral.",
    "Cumplir las normas y los procedimientos de forma activa.",
    "Capacidad para proponer nuevas formas de afrontar los problemas o los proyectos.",
    "Ser especialista en un área profesional.",
    "Habilidad para conseguir los resultados rápidamente con criterio de excelencia.",
    "Mantener el respeto por los protocolos establecidos y la calidad en un ambiente amable.",
    "Orientación hacia las tareas con eficiencia desde el inicio.",
    "Usar el recurso del tiempo con eficacia.",
    "Actitud reflexiva con capacidad para aceptar errores.",
    "Enfoque hacia la tarea.",
    "Velar por el cuidado de la calidad, sin descuidar los resultados.",
    "Jugar bien en equipo.",
    "Integración fácil con las personas que comprendan la importancia de la excelencia.",
    "Actitud respetuosa por los métodos, las normas y los procedimientos.",
    "Capacidad de flexibilidad, si el ambiente se lo exige.",
    "Ser disciplinado, detallista y perfeccionista.",
    "Usar la creatividad cuando se trata de resolver problemas nuevos.",
    "Ser firme bajo presión, tratando de mantener la diplomacia y los buenos modales.",
    "Ser honesto y modesto.",
    "Ser firme cuando la ocasión lo amerite.",
    "Aportar soluciones prácticas y metódicas.",
    "Probar nuevas ideas, cuando la metodología establecida no funciona.",
    "Calcular los riesgos posibles al tomar decisiones.",
    "Enfrentar los retos con responsabilidad.",
    "Mantener el foco en los protocolos probados.",
    "Exigencia en el mantenimiento de los estándares de calidad.",
    "Procurar que los estándares establecidos, no sean un obstáculo para alcanzar los resultados.",
    "Actuar con reflexión.",
    "Asumir acciones autónomas y adicionales, para mejorar los resultados.",
    "Capacidad de decisión en las situaciones rutinarias.",
    "Analizar muy bien las decisiones frente a los nuevos retos.",
    "Actitudes lógicas y racionales, con intuición y percepción.",
    "Habilidad para el pensamiento crítico y analítico.",
    "Mostar actitud resolutiva frente a los problemas."
  ],
  69: [
    "Aplicar métodos y protocolos en la solución de problemas nuevos.",
    "Enfoque principalmente hacia la acción y en la tarea.",
    "Ser una persona realizadora.",
    "Orientación hacia los procesos, la calidad y la obtención rápida de resultados.",
    "Mantener un buen clima laboral mientras se logran resultados de calidad.",
    "Asumir nuevos retos, analizando la información y hechos disponibles.",
    "Aportar creatividad y análisis para afrontar los retos.",
    "Calcular los riesgos, para resolver los problemas o diseñar protocolos.",
    "Influir con ritmo y calidad de trabajo en el entorno para enfrentar retos.",
    "A usted tiende a gustarle cumplir las normas y los procedimientos.",
    "Capacidad para proponer novedosas maneras de afrontar los problemas o los proyectos.",
    "Profesionalismo en el área de desempeño.",
    "Trabajar con eficiencia para conseguir los resultados rápidamente.",
    "Respetar los protocolos establecidos y los estándares de calidad.",
    "Ser consciente y consistente en las acciones.",
    "Capacidad para conciliar si es conveniente para los resultados y la calidad.",
    "Resolver los conflictos de forma imparcial y objetiva.",
    "Visión de futuro, al momento de plantear controles y protocolos.",
    "Enfoque en la solución de problemas y en los estándares de calidad.",
    "Poseer visión tanto global como de detalle, al enfocarse en un reto.",
    "Tomar decisiones con sentido de urgencia y análisis previo.",
    "Adecuar los ritmos diligentemente para decidir y solucionar, con el análisis que se requiere.",
    "Consciencia de los tiempos y de los plazos.",
    "Reconocer el esfuerzo de los demás.",
    "Valorar las pautas de lo que es un trabajo bien hecho.",
    "Entender las responsabilidades con sentido de libertad.",
    "Ser una persona discreta y reflexiva.",
    "Habilidad para debatir sobre análisis, hechos, soluciones e información.",
    "Presentar nuevas formas de hacer las cosas, con reflexión y análisis.",
    "Probar diferentes formas para resolver un problema.",
    "Actitud directa y honrada, a la hora de presentar los hechos.",
    "Probar nuevos procedimientos.",
    "Buscar mayor eficiencia, a la hora de alcanzar mejores estándares de calidad.",
    "Presentar lo realmente importante de un proyecto, problema o negocio.",
    "Creatividad en las soluciones, calculando bien los riesgos.",
    "Generar confianza y profesionalismo.",
    "Demostrar profesionalismo con el logro de resultados."
  ],
  70: [
    "Ser práctico, objetivo e incisivo.",
    "Cuidar de la calidad.",
    "Revisar la información disponible, antes de tomar una decisión.",
    "Enfoque en el trabajo desde el inicio.",
    "Hacer buen uso del tiempo para tornarlo un recurso productivo.",
    "Actitud reflexiva frente a los errores.",
    "Adecuar los tiempos para el cuidado de la calidad, sin descuidar los resultados.",
    "Integración al equipo.",
    "Respetar los métodos, las normas y los procedimientos.",
    "Impartir disciplina, precisión, detalle y perfección.",
    "Actitud firme bajo presión.",
    "Diplomacia y buenos modales.",
    "Mantener el orden, la limpieza y la organización en el lugar de trabajo.",
    "Honestidad en la presentación de los hechos.",
    "Encontrar soluciones prácticas y metódicas.",
    "Probar nuevas ideas cuando los protocolos establecidos no funcionen.",
    "Calcular los riesgos posibles, ante la toma de decisiones.",
    "Enfrentar los retos con responsabilidad.",
    "Exigir el mantenimiento de los estándares de calidad.",
    "Compromiso con las reglas de la organización.",
    "Reflexionar antes de actuar, controlando los impulsos.",
    "Hablar cuando esté seguro de los hechos o de la decisión tomada.",
    "Planificación.",
    "Manejar la información con criterio.",
    "Habilidad para evaluar a las personas.",
    "Emplear actitudes lógicas y racionales.",
    "Hacer uso de la intuición y la percepción.",
    "Habilidad para el pensamiento crítico y analítico en la resolución de los problemas.",
    "Ser consciente y consistente de forma activa."
  ],
  71: [
    "Ser directo y de ideas firmes con capacidad para transmitirlas con entusiasmo y amabilidad.",
    "Ser pionero en el área.",
    "Actitud abierta a conocer nuevas personas generando confianza.",
    "Marcar el ritmo en el equipo.",
    "Tener iniciativa y capacidad de integrar las ideas de los demás.",
    "Capacidad para ver el cuadro completo y comunicarlo a los otros de forma apropiada.",
    "Actitud optimista, a la hora de afrontar retos y riesgos.",
    "Equilibrar el enfoque en las necesidades de los demás con la forma de afrontar los retos.",
    "Ser sociable, amable, entusiasta, a la vez que exigente.",
    "Inspirar confianza en los demás.",
    "Tomar el liderazgo del proyecto o reto.",
    "Insistir en el logro de las tareas a través de la gente.",
    "Persistir en los logros y en los retos, sin pasar por alto las necesidades de las personas.",
    "Enfrentar nuevos retos y oportunidades.",
    "Adaptación a la rutina de trabajo, si la situación lo demanda.",
    "Tomar en cuenta a la gente para la toma de decisiones.",
    "Establecer niveles de control y estabilidad.",
    "Sentido de pertenencia al equipo de trabajo.",
    "Asumir posiciones de liderazgo.",
    "Ser una persona retadora y optimista.",
    "Actitud reflexiva, a la hora de plantearse metas de trabajo.",
    "Tomar decisiones con sentido de control de riesgo.",
    "Innovar respetando las formas tradicionales de hacer las cosas.",
    "Ser activo y relacionador.",
    "Aplicar la creatividad y al mismo tiempo seguir una metodología.",
    "Invertir tiempo en asesorar y ayudar a otros.",
    "Apreciar el orden y la estructura.",
    "Actitud motivadora e influyente para apaciguar a las personas.",
    "Habilidad de autocontrol.",
    "Actitud competitiva y persistencia en el logro de las metas.",
    "Cooperar con el equipo y con la organización.",
    "Firmeza y amistad, según lo requiera la situación."
  ],
  72: [
    "Equilibrar la atención en la gente, en la obtención de resultados y los procesos.",
    "Mostrar un buen nivel técnico en el área, a través de la obtención de resultados.",
    "Ejercer influencia en los demás, de forma considerada y no demandante.",
    "Lograr que los demás se proyecten con los resultados exitosos.",
    "Criterio experto.",
    "Gusto por la competencia en el logro de los retos y los desafíos profesionales.",
    "Habilidad para conectar con los demás.",
    "Ser amable, amigable y sociable.",
    "Firmeza bajo presión.",
    "Enfoque en la calidad, los resultados y la gente, logrando crear equipos eficientes y amigables.",
    "Ejercer un seguimiento cercano de las labores delegadas a los otros.",
    "Tomar en cuenta a los demás, a los hechos y la información, para tomar decisiones.",
    "Entusiasmo y motivación para competir y ganar en los proyectos.",
    "Competencia en el logro de los retos y los desafíos profesionales.",
    "Tomar la iniciativa para afrontar tareas y seguir los estándares de calidad.",
    "Habilidad para comunicar un plan detallado y los protocolos con entusiasmo.",
    "Capacidad de comunicación tanto con personas conocidas como con extraños.",
    "Estimular a los demás para que sigan las normas y los procesos establecidos.",
    "Estimular al equipo a resolver el conflicto en momentos de tensión.",
    "Mantener el nivel de desempeño del equipo en alto.",
    "Ser preciso, exacto y calculador.",
    "Asumir riesgos cuando sea preciso.",
    "Lograr resultados a través de la gente.",
    "Seguimiento al cumplimiento de los estándares de calidad.",
    "Asumir riesgos y enfrentar retos con optimismo realista.",
    "Preservar un sentido de urgencia moderado.",
    "Capacidad de análisis ante decisiones complejas.",
    "Ser un agente activo para el cambio.",
    "Estimular al equipo combinando diplomacia, optimismo y energía.",
    "Disposición para la acción y el cumplimiento de los procesos.",
    "Demostrar equilibrio entre la lógica, la razón, la emoción y la intuición.",
    "Cuidar de forma activa y enérgica, el logro de los resultados y la calidad.",
    "Manejar los sentimientos de forma positiva y orientada al resultado.",
    "Buenas relaciones con la gente."
  ],
  73: [
    "Asumir nuevos retos y problemas, analizando toda la información disponible.",
    "Abordar soluciones a partir de innovar en metodologías probadas.",
    "Usar la lógica, el análisis, la creatividad y el cálculo de riesgos, para afrontar los retos o diseñar protocolos.",
    "Manejar el sentido de urgencia en las rutinas y en la operatividad.",
    "Aplicar el análisis a los problemas o situaciones complejas.",
    "Probar diversas teorías y modelos antes de decidir y actuar.",
    "Cumplir las normas y procedimientos, con capacidad para proponer innovaciones.",
    "Explorar inusuales maneras de afrontar los problemas o los proyectos.",
    "Ser profesional en el área.",
    "Persistencia para conseguir los resultados rápidamente.",
    "Mantener el respeto por los protocolos establecidos y los estándares de calidad.",
    "Ser consciente y consistente de forma activa.",
    "Actitud conciliadora para resolver los conflictos de forma imparcial y objetiva.",
    "Visión de futuro, al momento de plantear controles y protocolos.",
    "Anticipación en la solución de problemas al implementar estándares de calidad.",
    "Enfoque en la visión tanto global como de detalle, ante un reto o problema.",
    "Ser detallista y a pausar el ritmo en situaciones adversas.",
    "Consciencia del tiempo y de los plazos.",
    "Ser paciente en la cotidianidad y firme en momentos de tensión.",
    "Discreción.",
    "Debatir sobre análisis, hechos, soluciones e información.",
    "Analizar nuevas formas de hacer las cosas.",
    "Responsabilidades con libertad de movimiento.",
    "Mostrar experticia, probando múltiples formas para resolver un problema.",
    "Actitud considerada y cuidado de la armonía en tiempos favorables.",
    "Enfrentar responsabilidades y retos, de forma metódica y sistemática.",
    "Persistencia en el seguimiento de los protocolos establecidos.",
    "Habilidades y talentos sólidos.",
    "Es realista ante los protocolos, sistemas, procedimientos, normas y metodologías.",
    "Enfoque en el cuidado de todos los aspectos del proyecto con detalle.",
    "Cuidar por el mantenimiento de los estándares de calidad.",
    "Adaptación al ritmo del entorno con foco en los resultados.",
    "Organización, orden y limpieza para trabajar.",
    "Asumir los cambios con prudencia y estabilidad.",
    "Presentar los hechos de manera honrada y directa.",
    "Probar nuevos y eficientes procedimientos.",
    "Innovar para alcanzar mejores estándares de calidad y un mejor rendimiento.",
    "Enfocar lo importante de un proyecto, problema o negocio.",
    "Usar la creatividad en la solución de problemas, calculando bien los riesgos.",
    "Preparar y generar la confianza con profesionalismo.",
    "Demostrar con el logro de resultados, el nivel de experticia."
  ],
  74: [
    "Actitud seria para afrontar tareas y proyectos.",
    "Habilidad para relajar el ambiente tenso, con buen humor y alegría.",
    "Demostrar un buen nivel técnico, en el área de conocimiento.",
    "Seguir normas y procedimientos con meticulosidad y detalle.",
    "Actitud crítica y optimista cuando es necesario.",
    "Integración al equipo de trabajo.",
    "Actitud desenvuelta para compartir con los demás.",
    "Ser de fácil trato.",
    "Dar feedback para corregir desviaciones.",
    "Capacidad de organización y autonomía en el trabajo.",
    "Comunicación clara y asertiva.",
    "Capacidad y profesionalismo en el área técnica, con habilidad y paciencia con las personas.",
    "Revisar información técnica y hechos, antes de tomar decisiones.",
    "Alcanzar altos niveles técnicos, profesionales y de conocimiento de la organización.",
    "Confiar en las habilidades de los demás.",
    "Entusiasmo vendiendo las ideas.",
    "Estrategias de persuasión vinculadas principalmente a la experticia.",
    "Persuadir a los demás con amabilidad y un toque de alegría.",
    "Enfoque en las tareas rutinarias o que estén a la mano.",
    "Ser detallista.",
    "Enfoque en los resultados con altos estándares de calidad.",
    "Mantener altos estándares de calidad.",
    "Adecuar el equilibrio en la forma de abordar los procesos, los protocolos y las personas.",
    "Demostrar un alto grado de capacidad en el área de conocimiento.",
    "Manejar buenas habilidades de comunicación."
  ]
};
