<template>
  <v-container fluid class="py-0 px-0 view-container" id="printPage">
    <report-header id="ready_to_know" class="topMarginNegative80">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>
      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col class="d-flex justify-end pr-sm-10 pr-2">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
            <!-- <o-tooltip value="Test">
              <v-btn>Test</v-btn>
            </o-tooltip> -->
          </v-col>
        </v-row>
      </template>
    </report-header>
    <section-ready-to-know :position="position" :candidate="candidate" />
    <section-behavior-graph
      :discData="discData"
      :discRoleData="discRoleData"
      :position="position"
    />
    <section-factors-power
      :discData="discData"
      :discRoleData="discRoleData"
      :position="position"
    />
    <multimap-graphic id="personal_graphic" :data="data" />
  </v-container>
</template>

<script lang="js">
import { formatDate } from "@/utils/date";

import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionReadyToKnow from "./components/SectionReadyToKnow.vue";
import SectionBehaviorGraph from "./components/SectionBehaviorGraph.vue";
import SectionFactorsPower from "./components/SectionFactorsPower.vue";
import MultimapGraphic from "@/views/reports/common/disc/MultimapGraphic";

export default {
  name: "ComparativeRoleDISC",
  components: {
    "report-header": ReportHeader,
    "applicant-data": ApplicantData,
    "section-ready-to-know": SectionReadyToKnow,
    "section-behavior-graph": SectionBehaviorGraph,
    "section-factors-power":SectionFactorsPower,
    "multimap-graphic": MultimapGraphic
  },
  data() {
    return {
      data: []
    };
  },
  computed: {
    applicantData() {
      return [
        { label: "Aplicante", value: this.applicantName },
        { label: "Correo Electrónico", value: this.applicantEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    applicantName() {
      return this.discRoleData ? this.discRoleData.applicantName : "-";
    },
    applicantEmail() {
      return this.discRoleData ? this.discRoleData.applicantEmail : "-";
    },
    submittedAt() {
      return this.discRoleData ? this.discRoleData.submittedAt : null;
    },
    position() {
      for (const item of this.data) {
        if (item.id === this.selectedId && item.modelType === 'ROLE_DISC') {
          return item.position || "-";
        }
      }
      return "-";
    },
    modelType() {
      for (const item of this.data) {
        if (item.id === this.selectedId && item.modelType === 'ROLE_DISC') {
          return item.modelType;
        }
      }
      return "-";
    },
    candidate() {
      for (const item of this.data) {
        if (item.id === this.selectedId && item.modelType === 'DISC') {
          return item.applicantName || "-";
        }
      }
      return "-";
    },
    discData() {
      for (const item of this.data) {
        if (item.id === this.selectedId && item.modelType === 'DISC') {
          return item;
        }
      }
      return null;
    },
    discRoleData() {
      for (const item of this.data) {
        if (item.id === this.selectedId && item.modelType === 'ROLE_DISC') {
          return item;
        }
      }
      return null;
    },
  },
  methods: {
    getReportData() {
      const reportIds = this.$route.query.id;
      if (reportIds && reportIds.length > 0) {
        this.data = [];
        reportIds.forEach(id => {
          this.$store
            .dispatch("getReportData", id)
            .then(response => {
              this.data.push(response);
            })
            .catch(error => {
              console.log(error);
            });
        });
      }
    },
    onPrint() {
      window.print();
    }
  },
  mounted() {
    this.getReportData();
  }
};
</script>

<style scoped>
@media print {
  @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
  .topMarginNegative80 {
    margin-top: -80px;
  }
  html {
    overflow-y: auto;
  }
  body {
    width: 12in;
    height: 18in;
    padding-top: 5px;
  }
}
</style>
